import styled, { media } from '@styled'
import { cover, rem } from 'polished'

export const Wrapper = styled.div`
  background: #f6f7f8;
  position: relative;
  &::before {
    padding-top: 80%;
    content: '';
    display: block;
    ${media.greaterThan('medium')`
      padding-top: 50%;
    `};
    ${media.greaterThan('large')`
      padding-top: 30%;
    `};
    ${media.greaterThan('huge')`
      padding-top: ${rem(500)};

    `};
  }
`

export const Background = styled.div`
  ${cover()};
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
`

export const Placeholder = styled(Background)`
  z-index: 1;
`
export const Image = styled(Background)<{
  small: any
  medium: any
  large: any
  huge: any
}>`
  z-index: 2;
  background-image: url(${p => p.small});
  ${media.greaterThan('medium')`
    background-image: url(${p => p.medium});
  `};
  ${media.greaterThan('large')`
    background-image: url(${p => p.large});
  `};
  ${media.greaterThan('huge')`
    background-image: url(${p => p.huge});
  `};
`
