import styled, { media } from '@styled'
import { Flex } from '@components/Layout'
import { rem, cover } from 'polished'

export const Container = styled(Flex)`
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  flex-wrap: wrap;
  margin-bottom: 4rem;

  ${media.greaterThan('medium')`
   max-width: ${rem(1000)};
  `};
  ${media.greaterThan('large')`
   max-width: ${rem(1100)};
  `};
  ${media.greaterThan('huge')`
  max-width: ${rem(1440)};
  `};
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${p => p.theme.colors.border};
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  &::before {
    padding-top: 60%;
    content: '';
    display: block;
  }
`

export const Image = styled.div`
  ${cover()};
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
`
