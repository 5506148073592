import { Primary } from '@components/Buttons'
import { Div } from '@components/Layout'
import { Text } from '@components/Typography'
import { AppConsumer } from '@config/appContext'
import React, { Component } from 'react'
import VideoPlayer from 'react-youtube'
import { Background, Body, Container, ContentContainer, Title, Video, VideoCta } from './elements'

class Hero extends Component<{ isLoggedIn: boolean }, { playing: boolean }> {
  state = {
    playing: false
  }
  handlePlayerReady = ({ target }) => {
    if (target && target.a && target.a.allow && target.a.allow.includes('autoplay')) {
      target.mute()
      target.playVideo()
    }
  }
  render() {
    return (
      <Background>
        <Container>
          <ContentContainer>
            <Title>Welkom op Identiteitsplein!</Title>
            <Body lineHeight={[1.2, 1.4, 1.6, 1.8]}>
              Welkom op Identiteitsplein! De plek om jezelf als christen en als leraar te
              ontwikkelen. Een plek vol inspiratie, visie, informatie en discussie. Ons doel: samen
              met elkaar het christelijk onderwijs voor onze leerlingen nog beter te maken.
            </Body>
            {!this.props.isLoggedIn && (
              <AppConsumer>
                {app => (
                  <Primary onClick={() => app && app.setActiveModal('register')}>
                    Schrijf je nu in
                  </Primary>
                )}
              </AppConsumer>
            )}
          </ContentContainer>
          <Div width={['100%', '45%']}>
            <Video>
              <VideoCta hide={this.state.playing}>
                <Text lineHeight={1} color="#fff" fontWeight="bold">
                  Identiteitsplein in 30 seconden
                </Text>
              </VideoCta>
              <VideoPlayer
                videoId="heMuPJS2lOE"
                onPlay={() => this.setState({ playing: true })}
                onReady={this.handlePlayerReady}
                opts={{ autoplay: 1 }}
              />
            </Video>
          </Div>
        </Container>
      </Background>
    )
  }
}

export default Hero
