import React, { Component, Fragment } from 'react'
import { CommentsQuery, COMMENTS } from './queries'
import { Avatar, AvatarImage, Name, Date, Container, Textarea } from './elements'
import { Grid, Div, Flex } from '@components/Layout'
import { LoadingText } from '@components/ContentLoaders'
import image from '@utils/image'
import { distanceInWordsToNow } from '@utils/date-fns'
import { Text } from '@components/Typography'
import { PrimaryLinkButton } from '@components/Buttons'
import { Comments_comments, Comments_comments_replies } from './__generated__/Comments'
import Form from './Form'

class Comment extends Component<
  {
    comment: Comments_comments | Comments_comments_replies
    discussionId: string
  },
  { showForm: boolean }
> {
  state = {
    showForm: false
  }
  toggleForm = () =>
    this.setState(({ showForm }) => ({
      showForm: !showForm
    }))
  render() {
    const { comment, discussionId } = this.props
    const { showForm } = this.state
    if (!comment) {
      return null
    }
    return (
      <Div pr={3}>
        <Grid
          gridTemplateColumns={['100%', '90px auto']}
          key={comment.id}
          mt={3}
          ml={comment.repliedTo ? 4 : 0}
        >
          <Div display={['none', 'block']}>
            <Avatar>
              {comment.user &&
                comment.user.avatar && (
                  <AvatarImage
                    src={image(comment.user.avatar.filename, { w: 50, h: 50, fit: 'crop' })}
                  />
                )}
            </Avatar>
          </Div>
          <Container>
            <Flex alignItems="center">
              <Name>{comment.user.display_name}</Name>{' '}
              <Date>{distanceInWordsToNow(comment.createdAt)} geleden</Date>
            </Flex>
            <Div mt={2}>
              <Text style={{ whiteSpace: 'pre-wrap' }} lineHeight="1.6rem">
                {comment.text}
              </Text>
            </Div>
            <Div />
            {!comment.repliedTo && (
              <Flex justifyContent="flex-end">
                <PrimaryLinkButton onClick={this.toggleForm}>
                  {showForm ? 'Annuleren' : 'Reageren'}
                </PrimaryLinkButton>
              </Flex>
            )}
          </Container>
        </Grid>
        {!comment.repliedTo &&
          showForm && (
            <Div mt={3} ml={4}>
              <Form id={discussionId} replyTo={comment.id} toggleForm={this.toggleForm} />
            </Div>
          )}
      </Div>
    )
  }
}

export default Comment
