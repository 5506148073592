import React from 'react'
import styled from '@styled'
import { rgba, rem } from 'polished'
import { Div } from '@components/Layout'

export const filterMessage = (message: string) => {
  return message && message.includes('GraphQL error')
    ? message.replace('GraphQL error:', '')
    : 'Something went wrong :('
}

const Wrapper = styled(Div)`
  min-height: ${rem(43)};
`
Wrapper.defaultProps = {
  pt: 3
}

const ErrorNoticeContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.red};
  background: ${props => rgba(props.theme.colors.red, 0.1)};
  color: ${props => props.theme.colors.red};
  font-size: ${rem(14)};
  line-height: ${1.6};
  padding: 0.5rem;
  border-radius: 3px;
`
const SuccessNoticeContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.primary};
  background: ${props => rgba(props.theme.colors.primary, 0.1)};
  color: ${props => props.theme.colors.primary};
  font-size: ${rem(14)};
  line-height: ${1.6};
  padding: 0.5rem;
  border-radius: 3px;
`
export const ErrorNotice = ({ message }) => (
  <Wrapper data-test-id="error-notice">
    {message && <ErrorNoticeContainer>{message}</ErrorNoticeContainer>}
  </Wrapper>
)
export const SuccessNotice = ({ message }) => (
  <Wrapper data-test-id="success-notice">
    {message && <SuccessNoticeContainer>{message}</SuccessNoticeContainer>}
  </Wrapper>
)
