import styled, { media } from '@styled'
import { Flex } from '@components/Layout'
import { rgba, size, darken } from 'polished'

export const Container = styled(Flex)`
  padding: 1rem;
  background: ${p => rgba(p.theme.colors.primary, 0.1)};
`
export const Button = styled.button`
  color: ${p => p.theme.colors.primaryDark};
  display: flex;
  align-items:center;
  padding: .5rem;
  font-weight: 500;
  border: 0;
  background: 0;
  margin-left: .5rem;
  cursor:pointer;
  &:hover {
    color: ${p => darken(.1, p.theme.colors.primaryDark)};
  }
  span {
    ${media.lessThan('medium')`
      display: none;
    `}
  }
  svg {
    ${size('1.25rem')};
    fill: currentColor;
    margin-right: .5rem;
  }
`
