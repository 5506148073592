import gql from 'graphql-tag'
import { Login, LoginVariables } from './__generated__/Login'
import { Mutation } from 'react-apollo'
import { RenewToken, RenewTokenVariables } from '@graphql/__generated__/RenewToken'
import { ConfirmEmail, ConfirmEmailVariables } from '@graphql/__generated__/ConfirmEmail'
export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

export class LoginMutation extends Mutation<Login, LoginVariables> {}

export const RENEW_TOKEN = gql`
  mutation RenewToken($token: String!) {
    renewToken(token: $token) {
      token
    }
  }
`

export class RenewTokenMutation extends Mutation<RenewToken, RenewTokenVariables> {}

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($token: String!) {
    confirmEmail(token: $token) {
      token
    }
  }
`

export class ConfirmEmailMutation extends Mutation<ConfirmEmail, ConfirmEmailVariables> {}
