import React, { Fragment } from 'react'
import { FeedQuery, FEED, HomepageArticleType } from '@scenes/Home/queries'
import ArticlePreview from '@components/ArticlePreview'
import { Container, Grid } from '@components/Layout'
import { HeadingOne } from '@components/Typography'

const Recommendations = () => {
  return (
    <Fragment>
      <HeadingOne>Uitgelicht</HeadingOne>
      <Container>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gridColumnGap={[0, '1rem', '1.5rem', '2rem']}
          gridRowGap="1rem"
        >
          <FeedQuery
            query={FEED}
            ssr={false}
            variables={{
              type: HomepageArticleType.RECOMMENDATIONS
            }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return [...Array.from(new Array(2))].map((u, i) => {
                  return (
                    <ArticlePreview
                      variant="horizontal"
                      key={`recommended-loading-${i}`}
                      loading={true}
                    />
                  )
                })
              }
              if (error || !data) {
                console.error(error)
                // TODO: proper error state
                return <div>Error</div>
              }
              return data.feed.map(article => {
                return <ArticlePreview key={article.id} variant="horizontal" article={article} />
              })
            }}
          </FeedQuery>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default Recommendations
