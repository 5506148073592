import Header from '@components/Header'
import Footer from '@components/Footer'
import { PageLoader } from '@components/Loaders'
import LoggedIn from '@components/LoggedIn'
import { AppProvider, Modals as ModalsType, State } from '@config/appContext'
import '@config/globalStyles'
import * as routes from '@config/routes'
import Login from '@scenes/Admin/Login'
import ArticleDetail from '@scenes/ArticleDetail'
import Home from '@scenes/Home'
import React, { Component, Fragment } from 'react'
import Loadable from 'react-loadable'
import { Route, Switch } from 'react-router-dom'
import CheckToken from './scenes/CheckToken'
import ConfirmEmail from './scenes/ConfirmEmail'
import Analytics from '@scenes/Analytics'
import { MenuButtonGlobal } from '@components/Menu'
import { GlobalStyle } from '@config/globalStyles'

const Admin = Loadable({
  loader: () => import('@scenes/Admin'),
  loading: PageLoader,
  delay: 1000
})
const Profile = Loadable({
  loader: () => import('@scenes/Profile'),
  loading: PageLoader,
  delay: 1000
})
const Error404 = Loadable({
  loader: () => import('@scenes/404'),
  loading: PageLoader,
  delay: 1000
})
const PageDetail = Loadable({
  loader: () => import('@scenes/PageDetail'),
  loading: PageLoader,
  delay: 1000
})
const Modals = Loadable({
  loader: () => import('@scenes/Modals'),
  loading: () => null
})

class App extends Component<any, State> {
  state = {
    mobileNavActive: false,
    modalActive: null
  }
  toggleMobileNavActive = () => {
    this.setState(
      ({ mobileNavActive, modalActive }) => ({
        mobileNavActive: !mobileNavActive,
        modalActive: mobileNavActive ? null : modalActive
      }),
      () => {
        if (this.state.mobileNavActive && document && document.documentElement) {
          document.documentElement.classList.add('modal-active')
        } else if (document && document.documentElement) {
          document.documentElement.classList.remove('modal-active')
        }
      }
    )
  }
  setActiveModal = (modalActive: ModalsType) => {
    if (document && document.documentElement) {
      if (!modalActive) {
        document.documentElement.classList.remove('modal-active')
      } else {
        document.documentElement.classList.add('modal-active')
      }
    }
    this.setState({ modalActive })
  }
  componentDidMount() {}
  render() {
    return (
      <Fragment>
        <GlobalStyle />
        <MenuButtonGlobal />
        <AppProvider
          value={{
            ...this.state,
            toggleMobileNavActive: this.toggleMobileNavActive,
            setActiveModal: this.setActiveModal
          }}
        >
          <CheckToken>
            <ConfirmEmail>
              <Fragment>
                <Header />
                <Analytics />
                <Switch>
                  <Route exact path={routes.HOME} component={Home} />
                  <Route path={routes.ARTICLE_DETIAL} component={ArticleDetail} />
                  <Route path={routes.PAGE_DETAIL} component={PageDetail} />
                  <Route
                    path={routes.ADMIN}
                    render={() => (
                      <LoggedIn>
                        {({ isLoggedIn }) => (isLoggedIn ? <Admin /> : <Login />)}
                      </LoggedIn>
                    )}
                  />
                  <Route
                    path={routes.PROFILE}
                    render={() => (
                      <LoggedIn>
                        {({ isLoggedIn }) => (isLoggedIn ? <Profile /> : <Error404 />)}
                      </LoggedIn>
                    )}
                  />
                  <Route component={Error404} />
                </Switch>
                <Modals />
                <Footer />
              </Fragment>
            </ConfirmEmail>
          </CheckToken>
        </AppProvider>
      </Fragment>
    )
  }
}

export default App
