import { LoadingImage, LoadingText } from '@components/ContentLoaders'
import { Div } from '@components/Layout'
import { Feed_feed } from '@scenes/Home/__generated__/Feed'
import image from '@utils/image'
import React from 'react'
import {
  CommentCount,
  ContainerVertical,
  ContentContainerVertical,
  Image,
  ImageContainerVertical,
  Text,
  TitleVertical
} from './elements'
import { getAltText } from '@utils/alt-text'

type Props = {
  loading?: boolean
  href: string
  article?: Feed_feed
}

const Vertical = ({ href, article, loading }: Props) => {
  return (
    <ContainerVertical to={href}>
      <ImageContainerVertical>
        {loading ? (
          <LoadingImage />
        ) : (
          <Image
            small={
              article && article.image ? image(article.image.path, { w: 750, fit: 'crop' }) : null
            }
            medium={
              article && article.image ? image(article.image.path, { w: 600, fit: 'crop' }) : null
            }
            alt={
              article &&
              article.image &&
              article.image.unsplashAuthor &&
              article.image.unsplashAuthorUsername
                ? getAltText(article.image.unsplashAuthor, article.image.unsplashAuthorUsername)
                : ``
            }
          />
        )}
        {/* {!loading && article ? <CommentCount count={article.commentCount} /> : null} */}
      </ImageContainerVertical>
      <ContentContainerVertical>
        {loading ? (
          <Div mt={3}>
            <LoadingText height={'1.5rem'} mb={2} />
            <LoadingText height={'1.5rem'} mb={2} width="90%" />
            <LoadingText height={'1.5rem'} mb={2} width="80%" />
          </Div>
        ) : (
          <TitleVertical>
            <span>{article ? article.title : null}</span>
          </TitleVertical>
        )}

        <Text>
          {loading ? (
            <Div mt={4}>
              <LoadingText mb={2} />
              <LoadingText mb={2} width="90%" />
            </Div>
          ) : article ? (
            article.intro
          ) : null}
        </Text>
      </ContentContainerVertical>
    </ContainerVertical>
  )
}

export default Vertical
