import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
// import * as serviceWorker from './serviceWorker'
const rootEl = document.getElementById('root')
const render = Component => ReactDOM.render(<Component />, rootEl)

render(Root)

if (module.hot) {
  module.hot.accept('./Root', () => {
    console.log('Rendering hot module')
    const Next = require('./Root').default
    render(Next)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
