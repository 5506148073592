import styled from '@styled'
import { darken } from 'polished'

const LinkBase = styled.button`
  background: 0;
  border: 0;
  padding: 0;
  outline: 0;
  font-weight: 500;
  cursor: pointer;
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`

export const PrimaryLinkButton = styled(LinkBase)`
  transition: color 0.2s ease-in-out;
  color: ${p => p.theme.colors.primaryDark};
  &:hover,
  &:focus {
    color: ${p => darken(0.1, p.theme.colors.primaryDark)};
  }
`
export const SecondaryLinkButton = styled(LinkBase)`
  transition: color 0.2s ease-in-out;
  color: ${p => p.theme.colors.secondary};
  &:hover,
  &:focus {
    color: ${p => darken(0.1, p.theme.colors.primaryDark)};
  }
`

export const UnderlinedLinkButton = styled(LinkBase)`
  transition: color 0.2s ease-in-out;
  color: ${p => p.theme.colors.secondary};
  border-bottom: 2px solid ${p => p.theme.colors.primaryDark};
  &:hover,
  &:focus {
    color: ${p => darken(0.1, p.theme.colors.primaryDark)};
  }
`
