import { External, Information } from '@components/Icons'
import { HeadingThree, HeadingTwo } from '@components/Typography'
import { convertToHTML } from 'draft-convert'
import React from 'react'

const uniqueId = () =>
  `hash-${Math.random()
    .toString(36)
    .substr(2, 9)}`

const decode = string => {
  const element = document.createElement('textarea')
  element.innerHTML = string
  return element.value
}

export const convert = content => {
  const html = convertToHTML({
    // @ts-ignore
    blockToHTML: block => {
      if (block.type === 'header-two') {
        return <HeadingTwo>{block.text}</HeadingTwo>
      }
      if (block.type === 'header-three') {
        return <HeadingThree>{block.text}</HeadingThree>
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        const id = uniqueId()
        return (
          <a
            href={entity.data.url}
            className="entity"
            target="_blank"
            id={id}
            data-type={entity.type}
          >
            {decode(originalText)}
            <External />
          </a>
        )
      }
      if (entity.type === 'INFORMATION') {
        const id = uniqueId()
        return (
          <span
            id={id}
            // data-description={entity.data.description}
            className="information entity"
            data-type={entity.type}
          >
            <span className="information__inner">
              {originalText}
              <Information />
            </span>
            <span className="information__content">{entity.data.description}</span>
          </span>
        )
      }
      if (entity.type === 'COLLAPSABLE_TEXT') {
        return originalText
      }
      if (entity.type === 'INTRO') {
        return <p className="intro">{decode(originalText)}</p>
      }
      if (entity.type === 'COLUMNS') {
        return <p className="columns">{decode(originalText)}</p>
      }
      return originalText
    }
  })(content)

  return {
    html
  }
}
