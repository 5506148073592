import styled, { media } from '@styled'
import { Text } from '@components/Typography'
import placeholder from './placeholder.png'
import placeholderMobile from './placeholder-mobile.png'
import bgMobile from './bg-mobile@2x.png'
import bgSmall from './bg@1x.png'
import bgLarge from './bg@2x.png'
import { rem, cover, size } from 'polished'

export const Background = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto 3rem 0;
  background-color: ${p => p.theme.colors.background};
  background-image: url(${placeholderMobile});
  background-size: cover;
  background-position: left bottom;

  ${media.greaterThan('medium')`
    background-image: url(${placeholder});

  `};
  &::before {
    padding-top: 170%;
    content: '';
    display: block;
    background-image: url(${bgMobile});
    background-size: cover;
    background-position: center bottom;
    ${media.greaterThan('small')`
      padding-top: 70%;
      background-image: url(${bgSmall});
    `};
    ${media.greaterThan('medium')`
      padding-top: 50%;
      background-image: url(${bgSmall});
    `};
    ${media.greaterThan('large')`
      padding-top: 40%;
      background-image: url(${bgLarge});
    `};
    ${media.greaterThan('huge')`
      padding-top: ${rem(500)};
    `};
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  ${media.greaterThan('small')`
      max-width: 50%;
    `};
  ${media.greaterThan('medium')`
    max-width: 50%;
    `};
  ${media.greaterThan('large')`
    max-width: 50%;
    `};
  ${media.greaterThan('huge')`
      max-width: ${rem(450)}
    `};
`

export const Container = styled.div`
  position: absolute;
  width: 90%;
  height: 100%;
  max-width: ${rem(1200)};
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Title = styled.h1`
  color: #fff;
  font-size: ${rem(36)};
  margin-bottom: 1rem;
  ${media.greaterThan('medium')`
    font-size: ${rem(40)};
  `};
  ${media.greaterThan('large')`
  font-size: ${rem(45)};
  `};
  ${media.greaterThan('huge')`
    font-size: ${rem(50)};
  `};
`

export const Body = styled(Text)`
  color: #fff;
  margin-bottom: 1.5rem;
`

export const Video = styled.div`
  ${cover()};
  ${size('100%')};
  box-shadow: 0 14px 40px rgba(0, 0, 0, 0.2);
  position: relative;
  background: ${p => p.theme.colors.background};
  iframe,
  span {
    ${cover()};
    ${size('100%')};
  }
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
`

export const VideoCta = styled.div<{ hide?: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: ${p => (p.hide ? `translateY(100px)` : `translateY(0)`)};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 0.25rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
`
