import styled, { media } from '@styled'
import { rem, darken, rgba, size } from 'polished'
import { TriangleDown } from '@components/Icons'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${p => p.theme.colors.primaryDark};
  min-height: ${rem(45)};
  cursor: pointer;
  &:hover {
    color: ${p => darken(0.1, p.theme.colors.primaryDark)};
  }
`

export const Carrot = styled(TriangleDown)`
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  margin-left: 0.75rem;
  transition: transform 0.25s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0;
`

export const Title = styled.div`
  font-size: ${rem(20)};
  cursor: pointer;
  position: relative;
  padding-left: 5rem;
  padding-right: 1rem;
`
export const Content = styled.div`
  display: none;
  ${media.greaterThan('medium')`
    margin-left: 5rem;
  `};
`

export const IconContainer = styled.div`
  transition: background 0.2s ease-in-out;
  ${size('4rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transform: rotate(-45deg);
  fill: currentColor;
  background: ${p => rgba(p.theme.colors.primary, 0.1)};
  position: absolute;
  svg {
    ${size('1.25rem')};
    transform: rotate(45deg);
  }
`

export const Container = styled.div<{ open: boolean }>`
  margin-top: -3rem;
  margin-bottom: 2rem;
  ${Carrot} {
    transform: ${p => (p.open ? `translateY(-50%) rotate(-180deg)` : `translateY(-50%)`)};
  }

  ${Content} {
    ${p =>
      p.open
        ? `
          display: block;
          background: ${rgba(p.theme.colors.primary, 0.1)};
          font-size: 0.9rem;
          padding: 1rem;
        `
        : `
          display: none;
        `};
  }
`

// &--open {
//   .collapsable-text__carrot {
//     transform: rotate(-180deg);
//   }
//   .collapsable-text__content {
//     display: block;
//     background: ${p => rgba(p.theme.colors.primary, 0.1)};
//     font-size: 0.9rem;
//     padding: 1rem;
//   }
// }
