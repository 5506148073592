import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { LOGGED_IN_STATUS, UPDATE_LOGGED_IN_STATUS } from '@graphql/linkState'

const LoggedIn = ({ children }) => {
  // @ts-ignore
  if (!process.browser) {
    return null
  }
  return (
    <Query query={LOGGED_IN_STATUS} ssr={false}>
      {({ loading, error, data }) => {
        if (loading || error || !data || !data.loggedInStatus) return null
        return children({ isLoggedIn: data.loggedInStatus.isLoggedIn })
      }}
    </Query>
  )
}

export default LoggedIn
