import React, { Component } from 'react'
import { graphql, compose, FetchResult, Mutation, MutationFn, MutationOptions } from 'react-apollo'
import { RENEW_TOKEN, RenewTokenMutation } from '@graphql/mutations'
import { UPDATE_LOGGED_IN_STATUS } from '@graphql/linkState'
import decode from 'jwt-decode'
import { PageLoader } from '@components/Loaders'
import { RenewToken, RenewTokenVariables } from '@graphql/__generated__/RenewToken'

type Props = {
  // updateLoggedInStatus: (isLoggedIn: boolean) => void
  updateLoggedInStatus: MutationFn<any, { isLoggedIn: boolean }>
  renewToken: MutationFn<RenewToken, RenewTokenVariables>
}

type State = {
  checked: boolean
}

class CheckToken extends Component<Props, State> {
  state = {
    checked: false
  }

  log = (message: string, add?: any): void =>
    !add ? console.log(`[AUTH] ${message}`) : console.log(`[AUTH] ${message}`, add)

  setLoggedInStatus = (isLoggedIn: boolean): void => {
    this.props.updateLoggedInStatus({ variables: { isLoggedIn } })
    this.setState({ checked: true })
  }

  async componentDidMount() {
    const token = window.localStorage.getItem('token')
    const fbToken = window.localStorage.getItem('fb-token')

    if (!token && !fbToken) {
      this.log(`No token found, so not logged in`)
      this.setLoggedInStatus(false)
      return
    }

    if (token) {
      await this.checkRegularToken(token)
    }

    if (!token && fbToken) {
      await this.checkFbToken()
    }
  }

  checkFbToken = async () => {
    this.log(`Found a Facebook token, let's check if FB is initialized`)
    // @ts-ignore
    if (window.FB) {
      // @ts-ignore
      window.FB.getLoginStatus(res => {
        console.log({ res })
        if (res.status === 'connected') {
          this.log(`Connected to FB & logged in`)
          this.setLoggedInStatus(true)
        } else {
          this.log(`Not connected with FB, logout`)
          this.setLoggedInStatus(false)
        }
      })
    } else {
      this.log(`FB is not initialized, logout`)
      this.setLoggedInStatus(false)
    }
  }
  checkRegularToken = async (token: string) => {
    const { renewToken } = this.props
    this.log(`Found a regular token, let's check if it's valid`)
    try {
      const decoded = decode(token)
      const now = Math.round(+new Date() / 1000)
      // @ts-ignore
      if (decoded.exp - now >= 10800) {
        this.log(`Regular token doesn't expire soon, so we'll keep the current one`)
        this.setLoggedInStatus(true)
        return
      } else {
        this.log(`Regular token expires within three hours or is expired, lets renew`)
        try {
          const result = await renewToken({ variables: { token } })
          if (result && result.data && result.data.renewToken.token) {
            this.log(`Renewed token!`)
            window.localStorage.setItem('token', result.data.renewToken.token)
            this.setLoggedInStatus(true)
            return
          }
        } catch (error) {
          this.log(`Failed to refresh token, logging out`, error)
          this.setLoggedInStatus(false)
          return
        }
      }
    } catch (error) {
      this.log(`Failed to decode regular token`, error)
      this.setLoggedInStatus(false)
      return
    }
  }
  render() {
    return this.state.checked ? this.props.children : <PageLoader />
  }
}

const CheckTokenWrapper = ({ children }) => (
  <RenewTokenMutation mutation={RENEW_TOKEN}>
    {renewToken => (
      <Mutation mutation={UPDATE_LOGGED_IN_STATUS}>
        {updateLoggedInStatus => (
          <CheckToken renewToken={renewToken} updateLoggedInStatus={updateLoggedInStatus}>
            {children}
          </CheckToken>
        )}
      </Mutation>
    )}
  </RenewTokenMutation>
)

export default CheckTokenWrapper
