import React, { Component } from 'react'
import TrackVisibility from 'react-on-screen'
import { Div } from '@components/Layout'
import LoggedIn from '@components/LoggedIn'
import { MarkArticleAsReadMutation, MARK_AS_READ } from './queries'
import { graphql } from 'react-apollo'
import extractId from '@utils/extractId'

class MutateMarkAsRead extends Component<{ markAsRead: () => any }, {}> {
  componentDidMount() {
    this.props.markAsRead()
  }
  render() {
    return this.props.children
  }
}

const MarkAsRead = ({ children, id }) => (
  <LoggedIn>
    {({ isLoggedIn }) =>
      isLoggedIn ? (
        <MarkArticleAsReadMutation mutation={MARK_AS_READ}>
          {markAsRead => (
            <TrackVisibility once>
              {({ isVisible }) =>
                isVisible ? (
                  <MutateMarkAsRead markAsRead={() => markAsRead({ variables: { id } })}>
                    {children}
                  </MutateMarkAsRead>
                ) : (
                  children
                )
              }
            </TrackVisibility>
          )}
        </MarkArticleAsReadMutation>
      ) : (
        children
      )
    }
  </LoggedIn>
)

export default MarkAsRead
