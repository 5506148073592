import { PrimaryLinkButton } from '@components/Buttons'
import { UserMenuProfileQuery, USER_MENU_PROFILE } from '@components/Header/AuthState/queries'
import { Div, Flex, Grid } from '@components/Layout'
import image from '@utils/image'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { DISCUSSION } from '../queries'
import { Comments } from '../__generated__/Comments'
import { Discussion } from '../__generated__/Discussion'
import { Avatar, AvatarImage, Container, Date, Name, Textarea } from './elements'
import { COMMENTS, CreateCommentMutation, CREATE_COMMENT } from './queries'

type FormValues = {
  text: string
}

const Form = ({
  id,
  replyTo,
  toggleForm
}: {
  id: string
  replyTo?: string
  toggleForm?: () => void
}) => {
  return (
    <UserMenuProfileQuery query={USER_MENU_PROFILE}>
      {({ error, data }) => {
        if (error) {
          return null
        }

        return (
          <Grid gridTemplateColumns={['100%', '90px auto']}>
            <Div display={['none', 'block']}>
              <Avatar>
                {data && data.profile && data.profile.avatar && (
                  <AvatarImage
                    src={image(data.profile.avatar.filename, { w: 50, h: 50, fit: 'crop' })}
                  />
                )}
              </Avatar>
            </Div>
            <Container>
              {data && data.profile && (
                <Flex alignItems="center" px={2} pt={2}>
                  <Name>{data.profile.display_name}</Name> <Date>Nu</Date>
                </Flex>
              )}
              <Div>
                <CreateCommentMutation
                  mutation={CREATE_COMMENT}
                  // @ts-ignore
                  update={(cache, { data: { createComment } }) => {
                    try {
                      const data: Comments | null = cache.readQuery({
                        query: COMMENTS,
                        variables: { discussionId: id }
                      })
                      let comments
                      if (data && data.comments) {
                        // If there are comments
                        if (createComment.repliedTo) {
                          const index = data.comments.findIndex(
                            c => c.id === createComment.repliedTo.id
                          )
                          comments = data.comments

                          comments[index] = {
                            ...comments[index],
                            replies: comments[index].replies
                              ? [...comments[index].replies, createComment]
                              : [createComment]
                          }
                        } else {
                          comments = [...data.comments, createComment]
                        }
                      } else {
                        // This is the first comment
                        comments = [createComment]
                      }

                      cache.writeQuery({
                        query: COMMENTS,
                        variables: { discussionId: id },
                        data: { comments }
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    try {
                      // Update comment count
                      const data: Discussion | null = cache.readQuery({
                        query: DISCUSSION,
                        variables: { id }
                      })
                      if (data && data.discussion) {
                        cache.writeQuery({
                          query: DISCUSSION,
                          variables: { id },
                          data: {
                            discussion: {
                              ...data.discussion,
                              commentCount: data.discussion.commentCount + 1
                            }
                          }
                        })
                      }
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                >
                  {(createComment, { loading, error }) => (
                    <Formik
                      initialValues={{ text: '' }}
                      onSubmit={(data, { resetForm }) => {
                        let input = {
                          text: data.text,
                          discussionId: id
                        }
                        if (replyTo) {
                          input['replyTo'] = replyTo
                        }
                        createComment({
                          variables: { data: input }
                        }).then(res => {
                          resetForm()
                          if (toggleForm) {
                            toggleForm()
                          }
                        })
                      }}
                      render={({
                        handleSubmit,
                        handleChange,
                        values,
                        touched
                      }: FormikProps<FormValues>) => (
                        <form onSubmit={handleSubmit}>
                          <Div py={3}>
                            <Textarea
                              name="text"
                              onChange={handleChange}
                              value={values.text}
                              placeholder="Schrijf hier een reactie"
                            />
                          </Div>
                          <Flex justifyContent="flex-end">
                            <PrimaryLinkButton
                              disabled={loading || !values.text.length}
                              type="submit"
                            >
                              {loading ? 'Versturen...' : 'Verstuur'}
                            </PrimaryLinkButton>
                          </Flex>
                        </form>
                      )}
                    />
                  )}
                </CreateCommentMutation>
              </Div>
            </Container>
          </Grid>
        )
      }}
    </UserMenuProfileQuery>
  )
}

export default Form
