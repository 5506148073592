import {
  format as dateFnsFormat,
  distanceInWordsToNow as dateFnsdistanceInWordsToNow
} from 'date-fns'
import dutch from 'date-fns/locale/nl'

export const format = (date: string, format: string) =>
  dateFnsFormat(date, format, {
    locale: dutch
  })

export const distanceInWordsToNow = (date: string, options?: any) =>
  dateFnsdistanceInWordsToNow(date, {
    ...options,
    locale: dutch
  })
