import styled, { media } from '@styled'
import { NavLink } from 'react-router-dom'

export const Link = styled(NavLink)`
  font-size: 1.5rem;
  margin: 1rem 0;
  display: block;
  transition: color 0.2s ease-in-out;
  color: ${p => p.theme.colors.body};
  letter-spacing: -0.2px;
  text-decoration: none;

  &.active,
  &:hover,
  &:focus {
    color: ${p => p.theme.colors.primaryDark};
  }

  ${media.greaterThan('large')`
    font-size: 1rem;
    margin: 0 1rem;
  `};
`
