import { ContentContainer } from '@components/Detail/Containers'
import { convertFromRaw } from 'draft-js'
import React, { Component } from 'react'
import AsideElements from './AsideElements'
import ContentStyles from './ContentStyles'
import { convert } from './utils'

type Props = {
  content: any
}

type State = {
  html: string
  elements: Array<HTMLElement>
  parentOffset: number
  collapsableTextOpen: null | string
}

class Text extends Component<Props, State> {
  parent: any = null
  constructor(props) {
    super(props)

    const { html } = convert(convertFromRaw(this.props.content))
    this.state = {
      html: html.replace('<p></p>', ''),
      elements: [],
      parentOffset: 0,
      collapsableTextOpen: null
    }
  }

  componentDidMount() {
    if (this.parent) {
      const elements = [...this.parent.querySelectorAll('.entity')]
      this.setState({ elements, parentOffset: this.parent.offsetTop })
      const collapsableElements = [
        ...this.parent.querySelectorAll('.collapsable-text__title-container')
      ]
      collapsableElements.forEach(el => {
        el.addEventListener('click', () => {
          if (el.dataset && el.dataset.parent) {
            const parent = document.getElementById(el.dataset.parent)
            if (parent) {
              parent.classList.toggle('collapsable-text--open')
            }
          }
        })
      })
    }
  }
  render() {
    return (
      <ContentContainer
        innerRef={ref => (this.parent = ref)}
        side={
          this.state.elements.length ? (
            <AsideElements parentOffset={this.state.parentOffset} elements={this.state.elements} />
          ) : null
        }
      >
        <ContentStyles dangerouslySetInnerHTML={{ __html: this.state.html }} />
      </ContentContainer>
    )
  }
}

export default Text
