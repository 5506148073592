import React from 'react'
import ReactGA from 'react-ga'

const Analytics = () => {
  if (
    window.location.origin === 'https://beta.identiteitsplein.nl' &&
    !window.location.pathname.includes('/admin/')
  ) {
    console.log(`[Analytics]: Tracking https://beta.identiteitsplein.nl`)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  if (
    window.location.origin === 'https://identiteitsplein.nl' &&
    !window.location.pathname.includes('/admin/')
  ) {
    console.log(`[Analytics]: Tracking https://identiteitsplein.nl`)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  return null
}

export default Analytics
