import styled, { theme, media } from '@styled'
import {
  color,
  fontSize,
  lineHeight,
  space,
  ColorProps,
  FontSizeProps,
  LineHeightProps,
  SpaceProps,
  fontWeight,
  FontWeightProps,
  opacity,
  OpacityProps,
  textAlign,
  TextAlignProps
} from 'styled-system'
import { rem } from 'polished'

export { Title as HeadingOne } from './HeadingOne'

type TextProps = ColorProps &
  FontSizeProps &
  LineHeightProps &
  SpaceProps &
  FontWeightProps &
  OpacityProps &
  TextAlignProps

export const Text = styled.div<TextProps>`
  font-family: ${p => p.theme.fonts.body};
  ${color};
  ${fontSize};
  ${lineHeight};
  ${space};
  ${fontWeight};
  ${opacity};
  ${textAlign};
`

Text.defaultProps = {
  color: theme.colors.heading,
  fontSize: 2,
  lineHeight: 1
}

type HeadingProps = SpaceProps

export const HeadingTwo = styled.h2<HeadingProps>`
  ${space};
  position: relative;
  font-size: ${rem(27)};
  ${media.greaterThan('medium')`
    font-size: ${rem(32)};
  `};
  ${media.greaterThan('huge')`
    font-size: ${rem(35)};
  `};
  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 1.5rem;
    top: 0.5rem;
    position: absolute;
    left: -2.5rem;
    width: 0;
    height: 0;
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-left: 0.75rem solid ${theme.colors.primary};
  }
`
HeadingTwo.defaultProps = {
  mb: 3
}

export const HeadingThree = styled.h3<HeadingProps>`
  ${space};
  position: relative;
  font-size: ${rem(22)};
  ${media.greaterThan('medium')`
    font-size: ${rem(25)};
  `};
  ${media.greaterThan('huge')`
    font-size: ${rem(28)};
  `};
  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 1.5rem;
    top: 0.25rem;
    position: absolute;
    left: -2.5rem;
    width: 0;
    height: 0;
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-left: 0.75rem solid ${theme.colors.primary};
  }
`

HeadingThree.defaultProps = {
  mb: 3
}
