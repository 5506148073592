import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import extractId from '@utils/extractId'
import { ArticleDetailQuery, ARTICLE_DETAIL } from '@scenes/ArticleDetail/queries'
import Hero from '@components/Detail/Hero'
import { Title } from '@components/Detail/Title'
import Blocks from '@components/Detail/Blocks'
import MarkAsRead from '@scenes/ArticleDetail/MarkAsRead'
import Recommendations from './Recommendations'
import Helmet from 'react-helmet'
import image from '@utils/image'
import Share from '@components/Detail/Share'
import Meta from './Meta'
import ScrollToTopOnMount from '@scenes/ScrollToTopOnMount'
type Props = RouteComponentProps<{ id: string }>

const ArticleDetail = (props: Props) => {
  const id = extractId(props.match.params.id)
  return (
    <Fragment>
      <ScrollToTopOnMount />

      <ArticleDetailQuery query={ARTICLE_DETAIL} variables={{ id }}>
        {({ loading, error, data }) => {
          if (error) {
            return <div>Error...</div>
          }

          return (
            <Fragment>
              {data && data.article ? (
                <Fragment>
                  <Helmet>
                    <title>{data.article.title} - Identiteitsplein</title>
                    <meta property="og:title" content={data.article.title} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    {data.article.description && (
                      <meta property="og:description" content={data.article.description} />
                    )}
                    {data.article.image && (
                      <meta
                        property="og:image"
                        content={image(data.article.image.path, { w: 2000, fit: 'crop' })}
                      />
                    )}
                  </Helmet>
                </Fragment>
              ) : null}
              <Hero
                loading={loading}
                image={data && data.article ? data && data.article.image : null}
              />

              {!loading && <Title>{data && data.article && data.article.title}</Title>}
              {!loading && data && data.article && <Meta {...data.article} />}

              <Blocks
                loading={loading}
                content={data && data.article ? data && data.article.content : []}
              />
              {data &&
                data.article && (
                  <Share title={data.article.title} description={data.article.description || ''} />
                )}

              <MarkAsRead id={id}>
                <Recommendations id={id} />
              </MarkAsRead>
            </Fragment>
          )
        }}
      </ArticleDetailQuery>
    </Fragment>
  )
}

export default ArticleDetail
