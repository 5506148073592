import { Div } from '@components/Layout'
import styled, { media } from '@styled'
import { rem, rgba } from 'polished'

export const Wrapper = styled(Div)`
  width: 100%;
  position: relative;
`

export const HeadingOne = styled.h1`
  font-size: ${rem(30)};
  line-height: 1.1;
  ${media.greaterThan('medium')`
    line-height: 1.2;
    font-size: ${rem(45)};
  `};
  ${media.greaterThan('large')`
    font-size: ${rem(50)};
  `};
  span {
    display: inline;
    background-size: 1px 1em;
    box-shadow: inset 0 -0.1em white, inset 0 -0.3em ${p => rgba(p.theme.colors.primary, 0.6)};
  }
`
