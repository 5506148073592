import React from 'react'

import styled from '@styled'

const StyledNotice = styled.div<{ type?: string; active: boolean }>`
  transition: transform 0.3s ease-in-out;
  background: ${p => (p.type === 'error' ? p.theme.colors.red : p.theme.colors.primaryDark)};
  z-index: 90;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: ${p => (p.active ? `translate(-50%, 0)` : `translate(-50%, 8rem)`)};
  padding: 1rem 2rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fff;
  font-weight: 500;
`

export const FixedNotice = ({ notice }) => (
  <StyledNotice type={notice && notice.type} active={notice.visible}>
    {notice && notice.message}
  </StyledNotice>
)
