import styled, { media } from '@styled'
import { rem, darken, rgba, size } from 'polished'

const ContentStyles = styled.div`
  p,
  li {
    color: ${p => p.theme.colors.body};
    font-size: ${rem(16)};
    line-height: 1.5;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    ${media.greaterThan('medium')`
      font-size: ${rem(18)};
    `};
    ${media.greaterThan('huge')`
      font-size: ${rem(18)};
    `};
    &:empty {
      display: none;
    }
    a,
    .information__inner {
      transition: color 0.2s ease-in-out;
      color: ${p => p.theme.colors.primaryDark};
      text-decoration: none;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      svg {
        width: 1rem;
        height: 1rem;
        display: inline-flex;
        align-self: center;
        margin-left: 2px;
        fill: currentColor;
      }
      &:hover,
      &:active {
        color: ${p => darken(0.1, p.theme.colors.primaryDark)};
      }
    }
  }

  .information {
    &--open {
      .information__content {
        display: block;
      }
    }
    &__content {
      background: red;
      display: none;
      background: #ecf9f1;
      padding: 0.25rem 0.5rem;
      font-size: ${rem(14)};
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }

  .intro {
    color: ${p => p.theme.colors.primaryDark};
    font-size: ${rem(18)};
    line-height: 1.5;
    ${media.greaterThan('medium')`
      font-size: ${rem(20)};
    `};
    ${media.greaterThan('huge')`
      font-size: ${rem(22)};
    `};
  }

  blockquote,
  .blockquote {
    font-size: ${rem(35)};
    color: ${p => p.theme.colors.primaryDark};
    font-family: ${p => p.theme.fonts.heading};
    line-height: ${rem(45)};
    margin-left: -2rem;
    position: relative;
    width: 80%;
    margin-left: 20%;
    &::before {
      height: 100%;
      width: 2rem;
      content: '»';
      position: absolute;
      top: 0;
      display: flex;
      color: ${p => p.theme.colors.heading};
      left: -2rem;
    }
  }

  .columns {
    column-count: 2;
    column-gap: 1rem;
    display: block;
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
    li,
    .unordered-list-item {
      position: relative;
      padding-left: 0.5rem;
      margin-bottom: 2rem;
      &::before {
        position: absolute;
        left: -1rem;
        font-size: 2rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        content: '•';
        color: ${p => p.theme.colors.primary};
      }
    }
  }

  ol {
    list-style-type: none;
    padding-left: 1rem;
    counter-reset: list-counter;
    li,
    .unordered-list-item {
      position: relative;
      padding-left: 0.5rem;
      &::before {
        position: absolute;
        left: -1rem;
        font-size: 1.15rem;
        font-weight: 500;
        line-height: 1;
        content: '•';
        height: 1.25rem;
        top: 0.3rem;
        content: counter(list-counter);
        counter-increment: list-counter;
        color: ${p => p.theme.colors.primary};
      }
    }
  }
`

export default ContentStyles
