import { Flex, Div } from '@components/Layout'
import * as routes from '@config/routes'
import { MenuItemsQuery, MENU_ITEMS } from '@graphql/queries'
import React, { Fragment } from 'react'
import { Link } from './elements'

type Props = {
  close?: () => void
}

const Navigation = ({ close }: Props) => {
  return (
    <Div display={['block', 'block', 'flex']} justifyContent="space-between" alignItems="center">
      <MenuItemsQuery query={MENU_ITEMS}>
        {({ loading, error, data }) => {
          if (loading || error || !data || !data.menuItems) return null
          return (
            <Fragment>
              <Link key="home" to="/" exact onClick={close}>
                Home
              </Link>
              {data.menuItems.map(item => (
                <Link
                  to={routes.PAGE_DETAIL.replace(':id', `${item.slug}-${item.id}`)}
                  key={item.id}
                  onClick={close}
                >
                  {item.menuTitle || item.title}
                </Link>
              ))}
            </Fragment>
          )
        }}
      </MenuItemsQuery>
    </Div>
  )
}

export default Navigation
