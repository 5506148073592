import styled from '@styled'

export const Container = styled.div<{ ratio: string }>`
  position: relative;
  width: 100%;
  &::before {
    transition: all 0.2s ease-in-out;
    display: block;
    content: '';
    padding-top: ${p => p.ratio};
  }
`
