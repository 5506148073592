import React from 'react'
import { RecommendationsQuery, RECOMMENDATIONS } from './queries'
import ArticlePreview from '@components/ArticlePreview'
import { Container, Grid, Div } from '@components/Layout'

const Recommendations = ({ id }: { id: string }) => {
  return (
    <Container mb={5} mt={'4rem'}>
      <Div mb={3}>
        <h1>Meer artikelen</h1>
      </Div>
      <Grid
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridColumnGap={[0, '1.5rem']}
        gridRowGap={['2rem', '1.5rem']}
      >
        <RecommendationsQuery query={RECOMMENDATIONS} variables={{ id_not_in: id }}>
          {({ loading, error, data }) => {
            if (loading) {
              return [...Array.from(new Array(2))].map((u, i) => (
                <ArticlePreview variant="horizontal" key={i} loading={true} />
              ))
            }
            if (error || !data) {
              console.error(error)
              // TODO: proper error state
              return <div>Error</div>
            }
            return data.recommendations.map(article => (
              <ArticlePreview key={article.id} variant="horizontal" article={article} />
            ))
          }}
        </RecommendationsQuery>
      </Grid>
    </Container>
  )
}

export default Recommendations
