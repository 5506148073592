import React from 'react'
import { CleanButton } from '@components/Buttons'
import styled from '@styled'
import { darken, rem } from 'polished'
import { Close } from '@components/Icons'
import posed from 'react-pose'

export const PosedContainer = posed.div({
  open: {
    translateY: 0,
    transition: {
      duration: 250
    }
  },
  closed: {
    translateY: '-2000px',
    transition: {
      duration: 350
    }
  }
})
export const Container = styled(PosedContainer)<{ isOpen: boolean }>`
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`

export const Inner = styled.div`
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
`

export const CloseButtonButton = styled(CleanButton)`
  background-color: ${p => p.theme.colors.border};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  &:focus,
  &:active {
    background-color: ${p => darken(0.1, p.theme.colors.border)};
  }
`

export const CloseButton = ({ onClick }) => (
  <CloseButtonButton onClick={onClick}>
    <Close />
  </CloseButtonButton>
)
