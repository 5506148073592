import {
  space,
  size,
  fontSize,
  color,
  display,
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  ratio,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  alignContent,
  justifySelf,
  alignSelf,
  order,
  flexBasis,
  gridGap,
  gridRowGap,
  gridColumnGap,
  gridColumn,
  gridRow,
  gridAutoFlow,
  gridAutoRows,
  gridAutoColumns,
  gridTemplateRows,
  gridTemplateColumns,
  position,
  zIndex,
  opacity,
  top,
  right,
  bottom,
  left,
  SpaceProps,
  SizeProps,
  JustifyContentProps,
  FlexWrapProps,
  FlexDirectionProps,
  AlignContentProps,
  OrderProps,
  FlexProps,
  AlignItemsProps,
  GridGapProps,
  GridRowProps,
  GridColumnProps,
  GridAutoFlowProps,
  GridAutoRowsProps,
  GridTemplatesColumnsProps,
  GridTemplatesRowsProps,
  GridRowGapProps,
  GridColumnGapProps,
  PositionProps,
  width,
  height,
  background,
  WidthProps,
  HeightProps,
  MaxHeightProps,
  TopProps,
  BottomProps,
  BorderProps,
  BackgroundProps,
  DisplayProps,
  BorderTopProps,
  BorderBottomProps,
  border,
  borderTop,
  borderBottom,
  ZIndexProps,
  borderRadius,
  BorderRadiusProps,
  LeftProps,
  RightProps,
  MinHeightProps,
  OpacityProps,
  MinWidthProps,
  MaxWidthProps,
  textAlign,
  TextAlignProps
} from 'styled-system'
import styled from '@styled'
import { rem, cover } from 'polished'

export type DivProps = SpaceProps &
  SizeProps &
  PositionProps &
  WidthProps &
  HeightProps &
  TopProps &
  BottomProps &
  BorderProps &
  BorderTopProps &
  BorderBottomProps &
  BackgroundProps &
  DisplayProps &
  AlignItemsProps &
  HeightProps &
  ZIndexProps &
  BorderRadiusProps &
  LeftProps &
  RightProps &
  JustifyContentProps &
  MinHeightProps &
  MinWidthProps &
  MaxWidthProps &
  OpacityProps &
  MaxHeightProps &
  TextAlignProps &
  FlexWrapProps

export const Div = styled.div<DivProps>`
  ${space};
  ${size};
  ${fontSize};
  ${color};
  ${width};
  ${height};
  ${background};
  ${display};
  ${maxWidth};
  ${minWidth};
  ${maxHeight};
  ${minHeight};
  ${ratio};
  ${alignItems};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flex};
  ${alignContent};
  ${justifySelf};
  ${alignSelf};
  ${order};
  ${flexBasis};
  ${gridGap};
  ${gridRowGap};
  ${gridColumnGap};
  ${gridColumn};
  ${gridRow};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridAutoColumns};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${position};
  ${zIndex};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${border};
  ${borderTop};
  ${borderBottom};
  ${borderRadius};
  ${opacity};
  ${textAlign};
`

export const Container = styled.div<SpaceProps>`
  ${space};
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem(1200)};
`

type GridComponentProps = SpaceProps &
  SizeProps &
  GridGapProps &
  GridRowProps &
  GridRowGapProps &
  GridColumnGapProps &
  GridColumnProps &
  GridAutoFlowProps &
  GridAutoRowsProps &
  GridTemplatesRowsProps &
  GridTemplatesColumnsProps &
  JustifyContentProps &
  AlignItemsProps

export const Grid = styled.section<GridComponentProps>`
  display: grid;
  ${space};
  ${size};
  ${gridGap};
  ${gridRowGap};
  ${gridColumnGap};
  ${gridColumn};
  ${gridRow};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridAutoColumns};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${justifyContent} ${alignItems};
`

type FlexComponentProps = SpaceProps &
  SizeProps &
  AlignItemsProps &
  JustifyContentProps &
  FlexWrapProps &
  FlexDirectionProps &
  FlexProps &
  AlignContentProps &
  OrderProps &
  HeightProps &
  WidthProps &
  DisplayProps

export const Flex = styled.div<FlexComponentProps>`
  display: flex;
  ${space};
  ${size};
  ${width};
  ${height};
  ${alignItems};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flex};
  ${alignContent};
  ${order};
  ${display};
`

export const CenteredContent = styled(Flex)`
  ${cover()};
  ${size('100%')};
`

CenteredContent.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap'
}
