import React, { Component } from 'react'

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    // Only scroll to top without hashes
    if (!window.location.hash) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

export default ScrollToTopOnMount
