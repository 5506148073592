import { Primary, PrimaryInverse } from '@components/Buttons'
import { Flex, Div } from '@components/Layout'
import LoggedIn from '@components/LoggedIn'
import { AppConsumer } from '@config/appContext'
import React from 'react'
import { withRouter } from 'react-router'
import UserMenu from './UserMenu'

const AuthState = () => {
  return null
  // return (
  //   <LoggedIn>
  //     {({ isLoggedIn }) => {
  //       return isLoggedIn ? (
  //         <UserMenu />
  //       ) : (
  //         <AppConsumer>
  //           {state =>
  //             state && (
  //               <Flex>
  //                 <Div mr={[2, 2, 3]}>
  //                   <Primary
  //                     style={{ padding: '.5rem 1.25rem', fontSize: 13 }}
  //                     onClick={() => {
  //                       state.setActiveModal('login')
  //                     }}
  //                   >
  //                     Inloggen
  //                   </Primary>
  //                 </Div>
  //                 <Div display={['none', 'none', 'block']}>
  //                   <PrimaryInverse
  //                     style={{ padding: '.5rem 1.25rem', fontSize: 13 }}
  //                     onClick={() => state.setActiveModal('register')}
  //                   >
  //                     Registeren
  //                   </PrimaryInverse>
  //                 </Div>
  //               </Flex>
  //             )
  //           }
  //         </AppConsumer>
  //       )
  //     }}
  //   </LoggedIn>
  // )
}

export default withRouter(AuthState)
