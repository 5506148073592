import React from 'react'
import styled from '@styled'
import { Div } from '@components/Layout'
import { darken, rgba, lighten, rem } from 'polished'

export const Icon = styled(Div)`
  transition: color 0.2s ease-in-out;
  color: ${p => rgba(p.theme.colors.primary, 1)};
  background: ${p => rgba(p.theme.colors.primary, 0.1)};
  font-weight: 500;
  width: ${rem(60)};
  height: ${rem(60)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
  }
`

export const Button = styled.a`
  transition: background 0.2s ease-in-out;
  background: ${p => p.theme.colors.primary};
  color: #fff;
  letter-spacing: 0.1px;
  text-align: center;
  font-family: ${p => p.theme.fonts.body};
  border-radius: 3px;
  padding: 1rem 2.5rem;
  border: 0;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    background: ${p => darken(0.1, p.theme.colors.primary)};
  }
  &:disabled {
    background: ${p => lighten(0.1, p.theme.colors.primary)};
    cursor: not-allowed;
  }
`
