import { Text } from '@components/Typography'
import styled from '@styled'
import { rem } from 'polished'

export const Label = styled.label`
  margin-bottom: 0.75rem;
  font-size: ${rem(16)};
  font-weight: 600;
  display: block;
`
