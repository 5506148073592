import { Check } from '@components/Icons'
import { Flex } from '@components/Layout'
import styled from '@styled'
import React, { Fragment } from 'react'
import { Label } from './elements'

const Item = styled(Flex)`
  position: relative;
  margin-bottom: 0.5rem;
  cursor: pointer;
`

const CustomRadio = styled.div`
  transition: all 0.2s ease-in-out;
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  border-radius: 0.675rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const RadioLabel = styled(Label)`
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 0.75rem;
`
const Radio = styled.input`
  background: aqua;
  cursor: pointer;
  + ${CustomRadio} svg {
    display: none;
    fill: #fff;
    width: 90%;
    height: 90%;
  }
  &:checked + ${CustomRadio} {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    svg {
      display: block;
    }
  }
`

type Props = {
  items: Array<{
    label: string
    value: string
  }>
  value?: string
  name: string
  label?: string
}

type RadioGroupProps = Props & React.InputHTMLAttributes<HTMLInputElement>

const RadioGroup = ({ items, value: inputValue, name, label, ...rest }: RadioGroupProps) => (
  <Fragment>
    {label && <Label>{label}</Label>}
    <Flex mb={2}>
      {items.map(({ label, value }) => (
        <Item key={value} flexWrap="wrap" alignItems="center" mr={2}>
          <Radio
            name={name}
            type="radio"
            id={value}
            value={value}
            checked={inputValue === value}
            {...rest}
          />
          <CustomRadio>
            <Check />
          </CustomRadio>
          <RadioLabel htmlFor={value}>{label}</RadioLabel>
        </Item>
      ))}
    </Flex>
  </Fragment>
)

export default RadioGroup
