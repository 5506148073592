import React from 'react'
import Youtube from '../Youtube'
import Image from '../Image'
import Discussion from '../Discussion'
import Text from '../Text'
import ImageColumn from '../ImageColumn'
import CollapsableText from '../CollapsableText'
import { Div, Container } from '@components/Layout'
import { LoadingText } from '@components/ContentLoaders'
import { ContentContainer } from '@components/Detail/Containers'
import Files from '../Files'

const Blocks = ({ content, loading }) => {
  if (loading) {
    return (
      <Div py={4}>
        <ContentContainer>
          <Div mb={4}>
            <LoadingText mb={2} height={'1.5rem'} width={'100%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'80%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'70%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'90%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'85%'} />
          </Div>
          <Div mb={4}>
            <LoadingText mb={2} height={'1.5rem'} width={'100%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'95%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'87.5%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'92.5%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'87.5%'} />
          </Div>
          <Div mb={4}>
            <LoadingText mb={2} height={'1.5rem'} width={'100%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'95%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'87.5%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'92.5%'} />
            <LoadingText mb={2} height={'1.5rem'} width={'87.5%'} />
          </Div>
        </ContentContainer>
      </Div>
    )
  }

  return content.map((block, index) => {
    switch (block.type) {
      case 'youtube':
        return <Youtube key={`block-${index}`} content={block.content} />
      case 'image':
        return <Image key={`block-${index}`} content={block.content} />
      case 'discussion':
        return <Discussion key={`block-${index}`} content={block.content} />
      case 'text':
        return <Text key={`block-${index}`} content={block.content} />
      case 'image-column':
        return <ImageColumn key={`block-${index}`} content={block.content} />
      case 'collapsable-text':
        return <CollapsableText key={`block-${index}`} content={block.content} />
      case 'files':
        return <Files key={`block-${index}`} content={block.content} />
      default:
        return null
    }
  })
}

export default Blocks
