import { defaults, resolvers } from '@graphql/linkState'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { withClientState } from 'apollo-link-state'
import { createUploadLink } from 'apollo-upload-client'

const API_URL = process.env.REACT_APP_API_URL || 'https://api.identiteitsplein.nl/graphql'
const cache = new InMemoryCache()

console.log(`[APOLLO] Connected to ${API_URL}`)

const middlewareLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  const fbToken = localStorage.getItem('fb-token')

  const authHeaders = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : fbToken ? `Bearer ${fbToken}` : ''
    }
  }

  return authHeaders
})

const uploadLink = middlewareLink.concat(
  createUploadLink({
    uri: API_URL
  })
)

const stateLink = withClientState({
  cache,
  resolvers,
  defaults
})

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([stateLink, uploadLink]),
  cache
})

export default client
