import styled from '@styled'

export const Container = styled.div`
  background-color: black;
  color: white;
  width: 8rem;
  height: 8rem;
  position: fixed;
  left: 50%;
  top: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`
