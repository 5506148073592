import styled, { css } from '@styled'
import { size, rem, rgba, darken } from 'polished'
import { Text as BaseText } from '@components/Typography'

export const IconContainer = styled.div`
  transition: background 0.2s ease-in-out;
  ${size('4rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
  fill: ${p => p.theme.colors.primaryDark};
  background: ${p => rgba(p.theme.colors.primary, 0.1)};
  position: absolute;
  left: 0;
  svg {
    ${size('1.25rem')};
    transform: rotate(45deg);
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  position: relative;
  padding-left: 4rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
`

export const Title = styled(BaseText)<{ open?: boolean }>`
  color: ${p => p.theme.colors.primaryDark};
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 500;
  svg {
    ${size('.5rem')};
    fill: currentColor;
    display: inline-flex;
    align-self: center;
    margin-left: 0.25rem;
    transform: ${p => (p.open ? `rotate(270deg)` : `rotate(90deg)`)};
  }
`

export const Text = styled(BaseText)<{ open?: boolean }>`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  width: calc(100% - 3.5rem);
  right: 0.25rem;
  padding: 0.25rem 0.75rem;
  position: absolute;
  ${p =>
    p.open
      ? css`
          background: #fff;
          z-index: 2;
          padding: 0.75rem;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
        `
      : null};
`

export const LinkContainer = styled.a<{ active?: boolean }>`
  text-decoration: none;
  position: relative;
  display: block;
  cursor: pointer;
  width: 100%;
  ${p =>
    p.active
      ? css`
          ${IconContainer} {
            background: ${p => rgba(p.theme.colors.primary, 0.3)};
          }
          ${Title} {
            color: ${p => darken(0.1, p.theme.colors.primaryDark)};
          }
        `
      : null} &:hover {
    ${IconContainer} {
      background: ${p => rgba(p.theme.colors.primary, 0.3)};
    }
    ${Title} {
      color: ${p => darken(0.1, p.theme.colors.primaryDark)};
    }
  }
`

export const ButtonContainer = LinkContainer.withComponent('button')
