import { Div, Flex } from '@components/Layout'
import { Text } from '@components/Typography'
import styled from '@styled'
import React from 'react'
import { Label } from './elements'

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 6rem;
  padding: 0.75rem;
  border: 1px solid ${p => p.theme.colors.border};
  border-radius: 0.25rem;
  outline: 0;
  &:focus {
    border-color: ${p => p.theme.colors.primaryDark};
  }
`

const Textarea = props => (
  <Div mb={3}>
    {props.label && <Label>{props.label}</Label>}
    <StyledTextarea {...props} />
    {props.maxLength && (
      <Flex justifyContent="flex-end" mt={2}>
        <Text fontSize={12} fontWeight={500}>
          {props.value.length}/{props.maxLength}
        </Text>
      </Flex>
    )}
  </Div>
)

export default Textarea
