import React from 'react'
import styled from '@styled'
import { darken, rgba, lighten } from 'polished'
export { CleanButton } from './CleanButton'
export { PrimaryLinkButton, SecondaryLinkButton, UnderlinedLinkButton } from './LinkButton'

const BaseButton = styled.button`
  letter-spacing: 0.1px;
  text-align: center;
  font-family: ${p => p.theme.fonts.body};
  border-radius: 3px;
  padding: 1rem 2.5rem;
  border: 0;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
`

export const Cancel = styled(BaseButton)`
  background: #fff;
  color: ${p => rgba(p.theme.colors.heading, 0.5)};
`

export const Primary = styled(BaseButton)`
  transition: background 0.2s ease-in-out;
  background: ${p => p.theme.colors.primary};
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    background: ${p => darken(0.1, p.theme.colors.primary)};
  }
  &:disabled {
    background: ${p => lighten(0.1, p.theme.colors.primary)};
    cursor: not-allowed;
  }
`

export const PrimaryInverse = styled(BaseButton)`
  transition: background 0.2s ease-in-out;
  background: #fff;
  border: ${p => ` 1px solid ${p.theme.colors.primary}`};
  color: ${p => p.theme.colors.primary};
  &:hover,
  &:active,
  &:focus {
    border-color: ${p => darken(0.1, p.theme.colors.primary)};
    color: ${p => darken(0.1, p.theme.colors.primary)};
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const Facebook = styled(BaseButton)`
  background: #4367b2;
  transition: background 0.2s ease-in-out;
  color: #fff;
  position: relative;
  width: 100%;
  svg {
    fill: #fff;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:active,
  &:focus {
    background: ${() => darken(0.1, '#4367b2')};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${() => lighten(0.1, '#4367b2')};
  }
`
