import ContentStyles from '@components/Detail/Text/ContentStyles'
import { convert } from '@components/Detail/Text/utils'
import { Div } from '@components/Layout'
import { IMAGE, ImageQuery } from '@graphql/queries'
import imageHelper from '@utils/image'
import { convertFromRaw } from 'draft-js'
import React from 'react'
import { Container, Image, ImageContainer } from './elements'
import { getAltText } from '@utils/alt-text'

const imageSizeMap = {
  'one-third': '40%',
  'two-fifth': '45%'
}
const textSizeMap = {
  'one-third': '60%',
  'two-fifth': '55%'
}

export interface ImageColumnProps {
  content: any
}

export interface ImageColumnState {}

class ImageColumn extends React.Component<ImageColumnProps, ImageColumnState> {
  parent = null
  componentDidMount() {
    if (this.parent) {
      // @ts-ignore
      const elements = [...this.parent.querySelectorAll('.information')]
      elements.forEach(el => {
        el.addEventListener('click', () => {
          el.classList.toggle('information--open')
        })
      })
    }
  }
  render() {
    const { content } = this.props
    if (!content || !content.imageId) {
      return null
    }
    const html = convert(convertFromRaw(content.text)).html
    return (
      <Container ref={ref => (this.parent = ref)}>
        <Div position="relative" width={['100%', imageSizeMap[content.size]]} mb={[3, 0]}>
          <ImageContainer>
            <ImageQuery query={IMAGE} variables={{ id: content.imageId }}>
              {({ loading, data, error }) => {
                if (loading || error) {
                  return null
                }

                if (!data || !data.image) {
                  return null
                }
                const { image } = data
                return (
                  <Image
                    style={{
                      backgroundImage: `url(${imageHelper(image.path, {
                        w: 700,
                        h: 420,
                        fit: 'crop'
                      })})`
                    }}
                    alt={
                      image && image.unsplashAuthor && image.unsplashAuthorUsername
                        ? getAltText(image.unsplashAuthor, image.unsplashAuthorUsername)
                        : ``
                    }
                  />
                )
              }}
            </ImageQuery>
          </ImageContainer>
        </Div>
        <Div
          position="relative"
          width={['100%', textSizeMap[content.size]]}
          mb={[3, 0]}
          p={4}
          pl={[0, 4, 5]}
        >
          <ContentStyles dangerouslySetInnerHTML={{ __html: html }} />
        </Div>
      </Container>
    )
  }
}

export default ImageColumn
