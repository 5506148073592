import { ContentContainer } from '@components/Detail/Containers'
import { DISCUSSION, DiscussionQuery } from '@components/Detail/Discussion/queries'
import { Comment } from '@components/Icons'
import { Div, Flex } from '@components/Layout'
import LoggedIn from '@components/LoggedIn'
import { HeadingTwo } from '@components/Typography'
import { AppConsumer } from '@config/appContext'
import React from 'react'
import Comments from './Comments'
import { CommentCount, Link, Title } from './elements'

const Discussion = ({ content }) => {
  return null
  if (!content.id) {
    return null
  }
  return (
    <DiscussionQuery query={DISCUSSION} variables={{ id: content.id }}>
      {({ loading, error, data }) => {
        if (loading) {
          return null
        }
        if (error) {
          console.error(error)
          return null
        }

        return (
          <Div
            minHeight={100}
            mb={'3rem'}
            id={`discussion-${data && data.discussion && data.discussion.id}`}
          >
            <ContentContainer mb={0}>
              <Flex justifyContent="space-between" alignItems="flex-end">
                <HeadingTwo mb={0}>Discussie</HeadingTwo>
                <CommentCount>
                  <Comment />{' '}
                  {data && data.discussion ? `${data.discussion.commentCount} reacties` : null}
                </CommentCount>
              </Flex>
              <Div my={3}>
                <Title>{data && data.discussion ? data.discussion.title : ''}</Title>
              </Div>
              <LoggedIn>
                {({ isLoggedIn }) =>
                  !isLoggedIn && (
                    <AppConsumer>
                      {state => (
                        <Link onClick={() => (state ? state.setActiveModal('login') : null)}>
                          Login om te reageren
                        </Link>
                      )}
                    </AppConsumer>
                  )
                }
              </LoggedIn>
            </ContentContainer>
            <LoggedIn>
              {({ isLoggedIn }) => {
                return isLoggedIn ? <Comments id={content.id} /> : null
              }}
            </LoggedIn>
          </Div>
        )
      }}
    </DiscussionQuery>
  )
}

export default Discussion
