import styled, { media } from '@styled'
import { rem, rgba, size } from 'polished'
import bgSmall from './bg@1x.png'
import bgLarge from './bg@2x.png'
import { Div } from '@components/Layout'

export const Grid = styled.div`
  position: relative;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.greaterThan('medium')`
    padding: 2rem 0;
    background-image: url(${bgSmall});
  `};
  ${media.greaterThan('huge')`
    background-image: url(${bgLarge});
  `};
  &::before {
    content: '';
    padding-top: 55%;
    display: block;
  }
`

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  max-width: ${rem(750)};
  min-height: ${rem(600)};
  display: flex;
  align-items: center;
  ${media.greaterThan('medium')`
      padding: 0 4rem;
  `};
`

export const Square = styled.div`
  width: 60%;
  background: ${p => rgba(p.theme.colors.primary, 0.1)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  &::before {
    padding-top: 100%;
    content: '';
    display: block;
  }
`

export const Title = styled.h1`
  font-size: ${rem(50)};
  line-height: 1;
  color: ${p => p.theme.colors.primaryDark};
  ${media.greaterThan('medium')`
    font-size: ${rem(60)};
    max-width: ${rem(340)};
  `};
`

export const Content = styled(Div)`
  color: ${p => p.theme.colors.heading};

  ${media.greaterThan('medium')`
    padding-left: 5rem;
  `};
  li {
    list-style-type: none;
    position: relative;
    margin-bottom: 1rem;
    &::before {
      ${size('1rem')};
      padding-top: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      left: -0.75rem;
      position: absolute;
      content: '•';
      font-size: 1.5rem;
      color: ${p => p.theme.colors.primary};
    }
  }
`
