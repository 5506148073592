import gql from 'graphql-tag'

export const resolvers = {
  Mutation: {
    setLoggedInStatus: async (_, { isLoggedIn }, { cache, ...rest }) => {
      const data = {
        loggedInStatus: {
          __typename: 'LoggedInStatus',
          isLoggedIn
        }
      }

      if (!isLoggedIn && typeof window !== 'undefined') {
        console.log(`[LINK-STATE] Logging out & removing token`)
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('fb-token')
      }
      cache.writeData({
        data
      })

      return null
    }
  }
}

const token = typeof window !== 'undefined' ? window.localStorage.getItem('token') : null

export const defaults = {
  loggedInStatus: {
    __typename: 'LoggedInStatus',
    isLoggedIn: token !== null,
    user: null
  }
}

export const LOGGED_IN_STATUS = gql`
  query LoggedInStatus {
    loggedInStatus @client {
      isLoggedIn
      user
    }
  }
`

export const UPDATE_LOGGED_IN_STATUS = gql`
  mutation SetLoggedInStatus($isLoggedIn: boolean!) {
    setLoggedInStatus(isLoggedIn: $isLoggedIn) @client {
      isLoggedIn
      user
    }
  }
`
