import React, { Component } from 'react'
import { ConfirmEmailMutation, CONFIRM_EMAIL } from '@graphql/mutations'
import { compose, graphql } from 'react-apollo'
import { UPDATE_LOGGED_IN_STATUS } from '@graphql/linkState'
import qs from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as routes from '@config/routes'
type Props = {
  confirm: (token: string) => any
  updateLoggedInStatus: (loggedIn: boolean) => any
}

type State = {
  ready: boolean
}

class ConfirmEmail extends Component<Props & RouteComponentProps<{}>, State> {
  state = {
    ready: false
  }
  componentDidMount() {
    const params = qs.parse(window.location.search)
    if (!params || !params['confirm-email']) {
      this.setState({ ready: true })
      return
    }

    const { confirm, history, updateLoggedInStatus } = this.props
    // @ts-ignore
    confirm(params['confirm-email'])
      .then(response => {
        updateLoggedInStatus(true)
        window.localStorage.setItem('token', response.data.confirmEmail.token)
        this.setState({ ready: true })
        history.push(`${routes.PROFILE}?email-confirmed=true`)
      })
      .catch(error => {
        console.error('Failed to confirm token')
        this.setState({ ready: true })
      })
  }
  render() {
    const { ready } = this.state
    return ready ? this.props.children : null
  }
}

export default compose(
  graphql(CONFIRM_EMAIL, {
    props: ({ mutate }) => ({
      confirm: (token: string) => mutate && mutate({ variables: { token } })
    })
  }),
  graphql(UPDATE_LOGGED_IN_STATUS, {
    props: ({ mutate }) => ({
      updateLoggedInStatus: (isLoggedIn: boolean) => mutate && mutate({ variables: { isLoggedIn } })
    })
  })
)(withRouter(ConfirmEmail))
