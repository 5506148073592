import React from 'react'
import styled, { css } from '@styled'
import { cover, size, rem } from 'polished'
import { Div } from '@components/Layout'
import { Youtube } from '@components/Icons'

export const YouTubeWrapper = styled.div`
  background-color: ${p => p.theme.colors.border};
  ${cover()};
  span {
    ${cover()};
    ${size('100%')};
  }
  iframe {
    ${cover()};
    ${size('100%')};
  }
`
