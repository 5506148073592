import React from 'react'
import { Container } from './elements'

type Props = {
  pastDelay?: boolean
  error?: Error
}

const PageLoader = ({ pastDelay, error }: Props) => {
  return error ? (
    <Container>Something went wrong, refresh the page</Container>
  ) : pastDelay ? (
    <Container>Loading...</Container>
  ) : null
}

export default PageLoader
