import styled, { keyframes } from '@styled'
import { css } from 'styled-components'
import { Div } from '@components/Layout'
import { rgba } from 'polished'
import { height, HeightProps, WidthProps, SpaceProps, width, space } from 'styled-system'

const loadingAnimation = keyframes`
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
`

const before = css`
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: ${loadingAnimation} 1.25s linear infinite;
    background: linear-gradient(
        to right,
        rgba(219, 221, 224, 0) 25%,
        ${p => rgba(p.theme.colors.secondary, 0.08)} 50%,
        rgba(219, 221, 224, 0) 75%
      )
      50% 50%;
  }
`

type LoadingTextProps = HeightProps & WidthProps & SpaceProps

export const LoadingText = styled(Div)<LoadingTextProps>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${p => rgba(p.theme.colors.secondary, 0.03)};
  ${height};
  ${width};
  ${space};
  ${before};
`

LoadingText.defaultProps = {
  height: '1rem',
  width: '100%'
}

type LoadingImageProps = SpaceProps

export const LoadingImage = styled(Div)<LoadingImageProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: ${p => rgba(p.theme.colors.secondary, 0.03)};
  ${space};
  ${before};
`
