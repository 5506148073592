import { Container, Grid } from '@components/Layout'
import Ratio from '@components/Ratio'
import { Text } from '@components/Typography'
import styled from '@styled'
import React from 'react'
import a from './a.png'
import ik from './i&k.png'
import sterkMerk from './sterk-merk.png'

const Img = styled.img`
  position: absolute;
  width: 80%;
  height: 80%;
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center center;
  left: 10%;
  top: 10%;
`

const Logos = () => (
  <Container py={3}>
    <Text textAlign="center" fontSize={25} fontWeight={500}>
      Identiteitsplein is een samenwerking van:
    </Text>
    <Grid
      gridTemplateColumns={['repeat(2, 1fr)', 'repeat(3, 200px)']}
      gridColumnGap={['0', '2rem']}
      gridRowGap={['0', '2rem']}
      justifyContent="center"
    >
      <Ratio ratio="60%">
        <Img src={sterkMerk} />
      </Ratio>
      <Ratio ratio="60%">
        <Img src={a} />
      </Ratio>
      <Ratio ratio="60%">
        <Img src={ik} />
      </Ratio>
    </Grid>
  </Container>
)

export default Logos
