import * as styledComponents from 'styled-components'
import { generateMedia } from 'styled-media-query'

export const media = generateMedia({
  small: '30em',
  medium: '48em',
  large: '64em',
  huge: '90em'
})

interface ThemeInterface {
  colors: {
    primary: string
    primaryDark: string
    secondary: string
    heading: string
    body: string
    border: string
    background: string
    red: string
  }
  fonts: {
    heading: string
    body: string
  }
  breakpoints: Array<string>
}

const theme = {
  colors: {
    primaryDark: '#34A662',
    primary: '#43C979',
    secondary: '#1E2441',
    heading: '#1E2441',
    body: '#1E2441',
    border: '#DBDDDF',
    background: '#F7F7F9',
    red: 'red'
  },
  fonts: {
    heading: "'Leitura', serif",
    body: "'Lota', sans-serif"
  },
  breakpoints: ['40em', '62.5em', '64em', '90em']
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>

export { css, createGlobalStyle, keyframes, ThemeProvider, theme }
export default styled
