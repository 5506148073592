import styled from '@styled'
import { Text } from '@components/Typography'
import { rem, rgba, size, darken } from 'polished'
import { CleanButton } from '@components/Buttons'

export const Title = styled(Text)`
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  color: ${p => p.theme.colors.primaryDark};
`

export const CommentCount = styled.div`
  font-size: ${rem(16)};
  color: ${p => rgba(p.theme.colors.heading, 0.6)};
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-top: 0.2rem;
  svg {
    ${size('1rem')};
    fill: currentColor;
    display: inline-flex;
    align-self: center;
    margin-right: 0.5rem;
  }
`

export const Link = styled(CleanButton)`
  transition: border-color ease-in-out 0.2s;
  font-size: ${rem(20)};
  font-weight: 500;
  color: ${p => p.theme.colors.heading};
  border-bottom: 2px solid ${p => p.theme.colors.primaryDark};
  padding: 0;
  &:hover,
  &:focus {
    border-color: ${p => darken(0.2, p.theme.colors.primaryDark)};
  }
`
