import React from 'react'
import { Grid, Container, Square, Title, Content } from './elements'
import { Div } from '@components/Layout'
import ContentStyles from '@components/Detail/Text/ContentStyles'
import { UnderlinedLinkButton } from '@components/Buttons'
import { AppConsumer } from '@config/appContext'
const Cta = () => {
  return (
    <Grid>
      <Container>
        <Div zIndex={2}>
          <Div mb={[3, 4]}>
            <Title>Waarom inschrijven?</Title>
          </Div>
          <Content width={'100%'}>
            <Div maxWidth={425} m="0 auto" pl={[0, 3]}>
              <ul>
                <li>Praat mee! En deel je ervaringen in het onderwijs</li>
                <li>Luister! En leer van anderen</li>
                <li>Help mee! Om het christelijk onderwijs (nog) beter te maken </li>
              </ul>
            </Div>
          </Content>
          <Div width="100%" textAlign="center" mt={[3, 4]}>
            <AppConsumer>
              {state => (
                <UnderlinedLinkButton
                  onClick={() => state && state.setActiveModal('register')}
                  style={{ marginLeft: '2rem' }}
                >
                  Meld je nu aan!
                </UnderlinedLinkButton>
              )}
            </AppConsumer>
          </Div>
        </Div>
        <Square />
      </Container>
    </Grid>
  )
}

export default Cta
