import gql from 'graphql-tag'
import {
  ArticleDetail,
  ArticleDetailVariables
} from '@scenes/ArticleDetail/__generated__/ArticleDetail'
import { Recommendations, RecommendationsVariables } from './__generated__/Recommendations'
import { MarkArticleAsRead, MarkArticleAsReadVariables } from './__generated__/MarkArticleAsRead'
import { Query, Mutation } from 'react-apollo'
export const ARTICLE_DETAIL = gql`
  query ArticleDetail($id: ID!) {
    article(id: $id) {
      id
      status
      title
      intro
      description
      image {
        id
        path
        unsplashAuthor
        unsplashAuthorUsername
      }
      content {
        type
        content
      }
      read
      readingTime
      authors
      createdAt
    }
  }
`

export class ArticleDetailQuery extends Query<ArticleDetail, ArticleDetailVariables> {}

export const RECOMMENDATIONS = gql`
  query Recommendations($id_not_in: ID!) {
    recommendations(id_not_in: $id_not_in) {
      id
      title
      slug
      description
      intro
      commentCount
      image {
        id
        path
        unsplashAuthor
        unsplashAuthorUsername
      }
    }
  }
`

export class RecommendationsQuery extends Query<Recommendations, RecommendationsVariables> {}

export const MARK_AS_READ = gql`
  mutation MarkArticleAsRead($id: ID!) {
    markArticleAsRead(id: $id) {
      id
    }
  }
`

export class MarkArticleAsReadMutation extends Mutation<
  MarkArticleAsRead,
  MarkArticleAsReadVariables
> {}
