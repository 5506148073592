import React from 'react'
import styled, { media } from '@styled'
import { Link } from 'react-router-dom'
import { rgba, cover, rem, size } from 'polished'
import { Text as BaseText } from '@components/Typography'
import { Div, Flex } from '@components/Layout'
import { Comment } from '@components/Icons'

const ImageContainerBase = styled.div`
  transition: box-shadow 0.2s ease-in-out;
  background: ${p => rgba(p.theme.colors.heading, 0.2)};
  width: 100%;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
  }
`

export const ImageContainerHorizontal = styled(ImageContainerBase)`
  ${media.greaterThan('large')`
    width: calc(50% - .5rem);
  `};
  &::before {
    position: relative;
    padding-top: 100%;
    ${media.greaterThan('medium')`
      padding-top: 80%;
    `};
    ${media.greaterThan('large')`
      padding-top: 130%;
      background-image: url(${p => p.medium});
    `};
  }
`
export const ImageContainerVertical = styled(ImageContainerBase)`
  &::before {
    padding-top: 80%;
    ${media.greaterThan('medium')`
      padding-top: 90%;
    `};
    ${media.greaterThan('huge')`
      padding-top: 105%;
    `};
  }
`

export const Image = styled.div<{
  small: string | null | undefined
  medium: string | null | undefined
}>`
  ${cover()};
  ${size('100%')};
  background-image: url(${p => p.small});
  background-size: cover;
  background-position: center center;
  ${media.greaterThan('large')`
      background-image: url(${p => p.medium});
  `};
`

const ContentContainerBase = styled.div`
  width: 100%;
  position: relative;
`

export const ContentContainerHorizontal = styled(ContentContainerBase)`
  min-height: 0;
  display: flex;
  flex-direction: column;
  ${media.greaterThan('large')`
    width: calc(50% - 1rem);
    padding-right: 1rem;
  `};
`
export const ContentContainerVertical = styled(ContentContainerBase)``

const TitleBase = styled.h2`
  letter-spacing: -0.2px;
  margin-bottom: 0.5rem;
  span {
    display: inline;
    background-size: 1px 1em;
    box-shadow: inset 0 -0.1em white, inset 0 -0.3em ${p => rgba(p.theme.colors.primary, 0)};
    transition: all 0.2s ease-in-out;
  }
`

export const TitleHorizontal = styled(TitleBase)`
  font-size: ${rem(30)};
  line-height: ${rem(40)};
  margin-top: 1rem;
  overflow-wrap: break-word;
  hyphens: auto;
  ${media.greaterThan('medium')`
    font-size: ${rem(28)};
    line-height: ${rem(37)};
  `};
`
export const TitleVertical = styled(TitleBase)`
  overflow-wrap: break-word;
  hyphens: auto;
  margin-top: 1rem;
  font-size: ${rem(25)};
  line-height: ${rem(33)};
`

export const ContainerHorizontal = styled(Link)`
  text-decoration: none;
  overflow: auto;

  ${media.greaterThan('large')`
     display: flex;
     flex-wrap: wrap;
      justify-content: space-between;
  `};

  &:hover {
    ${ImageContainerHorizontal} {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    ${TitleHorizontal} {
      span {
        box-shadow: inset 0 -0.1em white, inset 0 -0.3em ${p => rgba(p.theme.colors.primary, 0.6)};
      }
    }
  }
`
export const ContainerVertical = styled(Link)`
  display: block;
  text-decoration: none;
  overflow: auto;
  &:hover {
    ${ImageContainerVertical} {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    ${TitleVertical} {
      span {
        box-shadow: inset 0 -0.1em white, inset 0 -0.3em ${p => rgba(p.theme.colors.primary, 0.6)};
      }
    }
  }
`
export const Text = styled(BaseText)`
  font-size: ${rem(17)};
  line-height: 1.5;
  color: ${p => p.theme.colors.body};
`

const CommentCountContainer = styled.div`
  position: absolute;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  z-index: 1;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: 500;
  width: 100%;
  height: 30%;
  font-size: ${rem(15)};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentColor;
    margin-right: 0.5rem;
  }
`

export const CommentCount = ({ count }) => (
  <CommentCountContainer>
    <Div>
      <Flex alignItems="center" width="100%">
        <Comment />
        {count} reacties
      </Flex>
      <Div mt={1} opacity={0.6}>
        Discusieer mee
      </Div>
    </Div>
  </CommentCountContainer>
)
