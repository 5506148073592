import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { Profile } from './__generated__/Profile'
export const USER_MENU_PROFILE = gql`
  query Profile {
    profile {
      id
      display_name
      role
      avatar {
        id
        filename
      }
    }
  }
`

export class UserMenuProfileQuery extends Query<Profile, {}> {}
