import * as React from 'react'
import { ArticleDetail_article } from './__generated__/ArticleDetail'
import { Div, Container, Flex } from '@components/Layout'
import { Text } from '@components/Typography'
import { ContentContainer } from '@components/Detail/Containers'

const Meta: React.SFC<ArticleDetail_article> = ({ readingTime, authors }) => {
  return (
    <ContentContainer mb={[3, 4]} mt={2}>
      <Div display={['block', 'flex']}>
        {readingTime && (
          <Text fontSize={14} mb={[1, 0]}>
            <b>Leestijd:</b> {Math.round(readingTime / 1000 / 60)} minuten
          </Text>
        )}
        {authors && (
          <Text fontSize={14} ml={[0, 3]} mb={[1, 0]}>
            <b>Geschreven door:</b> {authors}
          </Text>
        )}
      </Div>
    </ContentContainer>
  )
}

export default Meta
