import styled from '@styled'
import { rem } from 'polished'
import { Text } from '@components/Typography'

export const Title = styled.div`
  margin: 0.75rem 0 1.5rem 0;
  font-family: ${p => p.theme.fonts.heading};
  color: ${p => p.theme.colors.primaryDark};
  letter-spacing: -0.13px;
  font-size: ${rem(25)};
`
export const Comment = styled.div`
  background: #ecf9f1;
  padding: 1rem;
  font-size: ${rem(20)};
  color: ${p => p.theme.colors.body};
  line-height: ${rem(30)};
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 1.5rem;
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #ecf9f1;
    bottom: -1rem;
    content: '';
  }
`
export const Avatar = styled.div`
  margin-right: 1rem;
  width: 3rem;
  position: relative;
  height: 3rem;
  border-radius: 1.5rem;
  position: relative;
  overflow: hidden;
  background: ${p => p.theme.colors.border};
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Author = styled.div`
  font-family: ${p => p.theme.fonts.heading};
  font-size: 16px;
  color: ${p => p.theme.colors.body};
  letter-spacing: -0.16px;
  margin-bottom: 0.25rem;
`
export const AuthorDescription = styled(Text)`
  font-size: ${rem(12)};
  color: #61657a;
`
