import * as React from 'react'
import { Div, Container, Flex } from '@components/Layout'
import { StyledFooter, RightImage, Logo, Link } from './elements'
import { Link as RouterLink } from 'react-router-dom'
import { MenuItemsQuery, MENU_ITEMS } from '@graphql/queries'
import { PAGE_DETAIL } from '@config/routes'
import logo from './logo.svg'
export interface FooterProps {}

const Footer: React.SFC<FooterProps> = () => {
  return (
    <StyledFooter py={5} mt={5}>
      <Container>
        <RightImage />
        <Div
          display={['block', 'block', 'flex']}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <RouterLink to="/">
            <Logo src={logo} />
          </RouterLink>
          <Div display={['block', 'block', 'flex']} ml={[0, -2]}>
            <MenuItemsQuery query={MENU_ITEMS}>
              {({ loading, error, data }) => {
                if (loading || error || !data) {
                  return null
                }
                return (
                  <React.Fragment>
                    <Link key="home" to="/" exact>
                      Home
                    </Link>
                    {data.menuItems.map(item => (
                      <Link
                        key={`footer-link-${item.id}`}
                        to={PAGE_DETAIL.replace(':id', `${item.slug}-${item.id}`)}
                      >
                        {item.menuTitle || item.title}
                      </Link>
                    ))}
                  </React.Fragment>
                )
              }}
            </MenuItemsQuery>
          </Div>
          <div />
        </Div>
      </Container>
    </StyledFooter>
  )
}

export default Footer
