import { FeedVariables, Feed } from './__generated__/Feed'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { LastComment } from './__generated__/LastComment'

export enum HomepageArticleType {
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  REMAINING = 'REMAINING'
}

export const FEED = gql`
  query Feed($type: HomepageArticleType!) {
    feed(type: $type) {
      id
      title
      slug
      description
      intro
      commentCount
      image {
        id
        path
        unsplashAuthor
        unsplashAuthorUsername
      }
    }
  }
`

export class FeedQuery extends Query<Feed, FeedVariables> {}

export const LAST_COMMENTS = gql`
  query LastComment {
    lastComments {
      id
      text
      discussion {
        id
        article {
          id
          slug
          title
        }
      }
      user {
        display_name
        description
        avatar {
          id
          filename
        }
      }
    }
  }
`

export class LastCommentsQuery extends Query<LastComment, {}> {}
