import * as routes from '@config/routes'
import { Feed_feed } from '@scenes/Home/__generated__/Feed'
import React from 'react'
import Horizontal from './Horizontal'
import Vertical from './Vertical'

type Props = {
  loading?: boolean
  variant: 'vertical' | 'horizontal'
  article?: Feed_feed
}

const ArticlePreview = ({ variant = 'vertical', article, loading }: Props) => {
  const href = article ? routes.ARTICLE_DETIAL.replace(':id', `${article.slug}-${article.id}`) : ''
  return variant === 'horizontal' ? (
    <Horizontal loading={loading} href={href} article={article} />
  ) : (
    <Vertical loading={loading} href={href} article={article} />
  )
}

export default ArticlePreview
