import { ContentContainer } from '@components/Detail/Containers'
import React from 'react'
import { ImgInside, ImgOutside } from './elements'
import { ImageQuery, IMAGE } from '@graphql/queries'
import imageHelper from '@utils/image'
import Hero from '@components/Detail/Hero'
import { Div } from '@components/Layout'
import Ratio from '@components/Ratio'
import { getAltText } from '@utils/alt-text'

const Image = ({ content }) => {
  if (!content || !content.imageId) {
    return null
  }
  return (
    <ImageQuery query={IMAGE} variables={{ id: content.imageId }}>
      {({ loading, data, error }) => {
        if (loading || error) {
          return null
        }

        if (!data || !data.image) {
          return null
        }
        const { image } = data

        if (content.size === 'wide') {
          return (
            <Div mb={5}>
              <Hero loading={false} image={image} />
            </Div>
          )
        }
        if (content.size === 'outside') {
          return (
            <ContentContainer padding={false}>
              <ImgOutside
                // @ts-ignore
                src={imageHelper(image.path, { w: 700, crop: 'fit' })}
                alt={
                  image && image.unsplashAuthor && image.unsplashAuthorUsername
                    ? getAltText(image.unsplashAuthor, image.unsplashAuthorUsername)
                    : ``
                }
              />
            </ContentContainer>
          )
        }
        return (
          <ContentContainer padding={false}>
            <Ratio ratio="70%">
              <ImgInside
                // @ts-ignore
                src={imageHelper(image.path, { w: 700, crop: 'fit' })}
                alt={
                  image && image.unsplashAuthor && image.unsplashAuthorUsername
                    ? getAltText(image.unsplashAuthor, image.unsplashAuthorUsername)
                    : ``
                }
              />
            </Ratio>
          </ContentContainer>
        )
      }}
    </ImageQuery>
  )
}

export default Image
