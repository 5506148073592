import App from './App'
import { BrowserRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import client from '@config/apollo'
import { theme, ThemeProvider } from '@styled'
import { init } from '@sentry/browser'
import { hot } from 'react-hot-loader'
import ReactGA from 'react-ga'

// @ts-ignore
if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://24c82f64217a44aab701a4c6735d8e0a@sentry.io/1276165'
  })
  ReactGA.initialize('UA-127630328-2')
}

const Root = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </ThemeProvider>
)

export default hot(module)(Root)
