import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Comments, CommentsVariables } from './__generated__/Comments'
import { CreateComment, CreateCommentVariables } from './__generated__/CreateComment'

const COMMENT_DETAIL_FRAGMENT = gql`
  fragment CommentDetail on Comment {
    id
    text
    createdAt
    discussion {
      id
    }
    repliedTo {
      id
    }
    user {
      id
      display_name
      avatar {
        id
        filename
      }
    }
    replies {
      id
      text
      createdAt
      discussion {
        id
      }
      repliedTo {
        id
      }
      user {
        id
        display_name
        avatar {
          id
          filename
        }
      }
    }
  }
`

export const COMMENTS = gql`
  query Comments($discussionId: ID!, $first: Int, $skip: Int) {
    comments(discussionId: $discussionId, first: $first, skip: $skip)
      @connection(key: "comments", filter: ["discussionId"]) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL_FRAGMENT}
`

export class CommentsQuery extends Query<Comments, CommentsVariables> {}

export const CREATE_COMMENT = gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      ...CommentDetail
    }
  }
  ${COMMENT_DETAIL_FRAGMENT}
`

export class CreateCommentMutation extends Mutation<CreateComment, CreateCommentVariables> {}
