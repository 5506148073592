import React from 'react'
import { ContentContainer } from '@components/Detail/Containers'
import Ratio from '@components/Ratio'
import VideoPlayer from 'react-youtube'
import qs from 'query-string'
import { YouTubeWrapper } from './elements'

const Youtube = ({ content }) => {
  if (!content.url) return null

  const parsed = qs.parseUrl(content.url)
  if (!parsed.query.v) return null

  return (
    <ContentContainer padding={false}>
      <Ratio ratio="56.25%">
        <YouTubeWrapper>
          {parsed.query.v ?
          // @ts-ignore
          <VideoPlayer videoId={parsed.query.v} /> : null}
        </YouTubeWrapper>
      </Ratio>
    </ContentContainer>
  )
}

export default Youtube
