import { HeaderContainer, StyledHeader } from '@components/Header/elements'
import { Div, Flex } from '@components/Layout'
import React, { Component, Fragment } from 'react'
import Navigation from './Navigation'
import Search from './Search'
import AuthState from './AuthState'
import MobileMenu from './MobileMenu'
import { AppConsumer, AppState } from '@config/appContext'
import { ReactComponent as Logo } from './logo.svg'
import { Link } from 'react-router-dom'
import { CleanButton } from '@components/Buttons'
import { Hamburger } from '@components/Icons'

class Header extends Component {
  render() {
    return (
      <AppConsumer>
        {state => {
          return !state ? null : (
            <Fragment>
              <MobileMenu
                isOpen={state.mobileNavActive}
                close={() => state.toggleMobileNavActive()}
                setActiveModal={modal => state.setActiveModal(modal)}
              >
                Mobilemnu...
              </MobileMenu>
              <StyledHeader>
                <HeaderContainer justifyContent="space-between" alignItems="center" flexWrap="wrap">
                  <Flex alignItems="center" height="100%" flexWrap="wrap">
                    <Div mr={2} maxWidth={[120, 160]}>
                      <Link to="/">
                        <Logo style={{ width: '100%', height: 'auto' }} />
                      </Link>
                    </Div>
                    <Div ml={3} display={['none', 'none', 'block']} mt={1}>
                      {/* <Search /> */}
                    </Div>
                  </Flex>
                  <Div alignItems="center" display={['none', 'none', 'flex']}>
                    <Navigation />
                    {/* <Div>
                      <AuthState />
                    </Div> */}
                  </Div>
                  <Div display={['flex', 'flex', 'none']} alignItems="center">
                    <AuthState />
                    <CleanButton
                      onClick={() => state.toggleMobileNavActive()}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Hamburger />
                    </CleanButton>
                  </Div>
                </HeaderContainer>
              </StyledHeader>
            </Fragment>
          )
        }}
      </AppConsumer>
    )
  }
}

export default Header
