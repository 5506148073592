import React from 'react'

import { ReactComponent as FacebookIcon } from './facebook.svg'
import { ReactComponent as TwitterIcon } from './twitter.svg'
import { ReactComponent as LinkedinIcon } from './linkedin.svg'
import { ReactComponent as TriangleDownIcon } from './triangle-down.svg'
import { ReactComponent as CollapsableTextIcon } from './collapsable-text.svg'
import { ReactComponent as HamburgerIcon } from './hamburger.svg'

export const Facebook = FacebookIcon
export const Twitter = TwitterIcon
export const TriangleDown = TriangleDownIcon
export const CollapsableText = CollapsableTextIcon
export const Hamburger = HamburgerIcon
export const LinkedIn = LinkedinIcon

export const Check = props => (
  <svg {...props} viewBox="0 0 24 24">
    <path d="m0 0h24v24h-24z" fill="none" />
    <path d="m9 16.17-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z" />
  </svg>
)

export const Close = props => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h24v24h-24z" fill="none" />
    <path d="m19 6.41-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" />
  </svg>
)

export const Upload = props => (
  <svg {...props} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 3.24v11.01c0 .525-.448.95-1 .95s-1-.425-1-.95V3.24L4.7 6.374c-.4.32-.99.294-1.357-.06a.916.916 0 0 1-.043-1.29l5-4.75a1.038 1.038 0 0 1 1.4 0l5 4.75c.287.23.414.594.329.942a.975.975 0 0 1-.733.706c-.365.086-.75-.03-.996-.298L10 3.23v.01zM0 14.25c0-.525.448-.95 1-.95s1 .425 1 .95v2.85h14v-2.85c0-.525.448-.95 1-.95s1 .425 1 .95v2.85c0 1.05-.895 1.9-2 1.9H2c-1.105 0-2-.85-2-1.9v-2.85z"
      fillRule="nonzero"
    />
  </svg>
)

export const Camera = props => (
  <svg {...props} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h2.38L6.11.55A1 1 0 0 1 7 0h6a1 1 0 0 1 .9.55L15.61 4H18zM7.62 2L5.89 5.45A1 1 0 0 1 5 6H2v10h16V6h-3a1 1 0 0 1-.9-.55L12.39 2H7.62zM10 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
      fillRule="nonzero"
    />
  </svg>
)

export const Grid = props => (
  <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="m2 0h4a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4zm-10 8h4a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4z" />
  </svg>
)

export const Image = props => (
  <svg {...props} viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg">
    <path d="m26.556.444h-24.112c-.95 0-1.722.772-1.722 1.723v20.666c0 .951.771 1.723 1.722 1.723h24.112c.95 0 1.722-.772 1.722-1.723v-20.666c0-.951-.771-1.723-1.722-1.723zm-24.112 22.39v-20.668h24.112v20.666h-24.112z" />
    <path d="m6.681 9.056a2.583 2.583 0 1 0 0-5.167 2.583 2.583 0 0 0 0 5.167zm0-3.962a1.378 1.378 0 1 1 -1.37 1.378 1.378 1.378 0 0 1 1.37-1.369zm11.935 5.141-4.65 4.65-3.444-3.444a.861.861 0 0 0 -1.215 0l-5.21 5.278v2.437l5.848-5.847 2.833 2.79-3.23 3.23h2.369l7.276-7.277 5.64 5.615v-2.429l-5.003-5.003a.861.861 0 0 0 -1.214 0z" />
  </svg>
)

export const File = props => (
  <svg {...props} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 0h9a1 1 0 0 1 .7.3l4 4a1 1 0 0 1 .3.7v13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2C0 .9.9 0 2 0zm9 2.41V5h2.59L11 2.41zM14 7h-3a2 2 0 0 1-2-2V2H2v16h12V7zm-2 7a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1zm0-4a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1zM7 6a1 1 0 0 1-1 1H5a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1z"
      fillRule="nonzero"
    />
  </svg>
)

export const Search = props => (
  <svg {...props} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.57 16.869c.506.572.557 1.094.151 1.565l-1.166 1.162c-.608.539-1.183.539-1.724 0l-4.816-4.798c-1.25.707-2.568 1.06-3.954 1.06-2.163 0-4.047-.8-5.653-2.398C.803 11.86 0 9.983 0 7.828s.76-3.998 2.281-5.53C3.802.766 5.644 0 7.807 0s4.056.8 5.678 2.399c1.623 1.6 2.434 3.476 2.434 5.631a7.579 7.579 0 0 1-1.166 4.091l4.816 4.748M2.383 7.829c0 1.48.574 2.794 1.723 3.939 1.15 1.144 2.468 1.717 3.955 1.717s2.763-.53 3.827-1.591c1.065-1.06 1.597-2.349 1.597-3.864 0-1.481-.574-2.786-1.723-3.914-1.15-1.128-2.468-1.692-3.955-1.692s-2.763.53-3.827 1.591c-1.065 1.06-1.597 2.332-1.597 3.813"
      transform="translate(6 4)"
    />
  </svg>
)

export const Plus = props => (
  <svg {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 1v12M1 7h12"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const ChevronUp = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 9l8-8 8 8"
      stroke="#34A662"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const ChevronDown = props => (
  <svg {...props} viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 1L9 9 1 1"
      stroke="#34A662"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const Delete = props => (
  <svg {...props} viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.889h13M12.556 3.889V14c0 .798-.647 1.444-1.445 1.444H3.89A1.444 1.444 0 0 1 2.444 14V3.889m2.167 0V2.444C4.611 1.647 5.258 1 6.056 1h2.888c.798 0 1.445.647 1.445 1.444V3.89M6.056 7.5v4.333M8.944 7.5v4.333"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const External = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      d="M 26 8 C 26 8.550, 27.350 9, 29 9 C 30.650 9, 32 9.327, 32 9.726 C 32 10.126, 28.762 13.713, 24.804 17.697 C 20.846 21.682, 17.934 25.268, 18.333 25.667 C 18.732 26.066, 22.318 23.154, 26.303 19.196 C 30.287 15.238, 33.874 12, 34.274 12 C 34.673 12, 35 13.350, 35 15 C 35 16.650, 35.450 18, 36 18 C 36.550 18, 37 15.525, 37 12.500 L 37 7 31.500 7 C 28.475 7, 26 7.450, 26 8 M8.200 12.200 C 6.554 13.846, 6.554 34.154, 8.200 35.800 C 9.846 37.446, 30.154 37.446, 31.800 35.800 C 33.222 34.378, 33.444 22, 32.048 22 C 31.524 22, 30.961 24.813, 30.798 28.250 L 30.500 34.500 20 34.500 L 9.500 34.500 9.500 24 L 9.500 13.500 15.750 13.202 C19.188 13.039, 22 12.476, 22 11.952 C 22 10.556, 9.622 10.778, 8.200 12.200 "
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
)
export const Information = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      fillRule="nonzero"
      d="M21 30h3v-9h-3v9zm1.5-23C13.944 7 7 13.944 7 22.5 7 31.056 13.944 38 22.5 38 31.056 38 38 31.056 38 22.5 38 13.944 31.056 7 22.5 7zm0 28C15.61 35 10 29.39 10 22.5S15.61 10 22.5 10 35 15.61 35 22.5 29.39 35 22.5 35zM21 18h3v-3h-3v3z"
    />
  </svg>
)
export const Edit = props => (
  <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M19 13.66V19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.34" />
      <path d="M17 1l4 4-10 10H7v-4z" />
    </g>
  </svg>
)
export const Youtube = props => (
  <svg {...props} viewBox="0 0 30 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.324 3.286A3.755 3.755 0 0 0 26.681.628C24.349 0 15 0 15 0S5.65 0 3.32.628A3.755 3.755 0 0 0 .675 3.286C.05 5.63.05 10.52.05 10.52s0 4.891.625 7.236c.344 1.293 1.357 2.27 2.643 2.615C5.651 21 15 21 15 21s9.35 0 11.68-.628c1.287-.346 2.3-1.322 2.644-2.615.625-2.345.625-7.236.625-7.236s0-4.89-.625-7.235zM11.942 14.962V6.08l7.814 4.441-7.814 4.441z"
      fill="red"
    />
  </svg>
)

export const Comment = props => (
  <svg {...props} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12V2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v15a1 1 0 0 1-1.7.7L14.58 14H2a2 2 0 0 1-2-2zM18 2H2v10h13a1 1 0 0 1 .7.3l2.3 2.29V2z" />
  </svg>
)

export const HeadingOne = props => (
  <svg {...props} viewBox="0 0 44 44">
    <g fillRule="evenodd">
      <path d="M20.635 10h4.904v24.377h-4.904v-10.27H9.904v10.27H5V10h4.904v9.808h10.731zM37.673 32.127h1.9v2.061H33v-2.06h2.239V25.09c-.622.32-1.173.427-2.203.427v-2.043c1.368 0 2.167-.391 2.736-1.475h1.9v10.127z" />
    </g>
  </svg>
)
export const HeadingTwo = props => (
  <svg {...props} viewBox="0 0 44 44">
    <g fillRule="evenodd">
      <path d="M20.204 10.469h4.781v24.01h-4.781V24.364H9.741v10.115H4.959v-24.01h4.782v9.66h10.463zM35.102 32.45h4.625v2.03h-8.124v-1.61l4.122-4.183c.797-.805 1.386-1.61 1.386-2.625 0-1.103-.606-1.628-1.541-1.628-1.023 0-1.75.683-1.75 2.013l-2.408.017c0-2.642 1.732-4.147 4.14-4.147 2.426 0 3.985 1.435 3.985 3.745 0 1.575-.71 2.695-2.148 4.112l-2.287 2.275z" />
    </g>
  </svg>
)
export const Intro = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      d="M 0 23 C 0 28.556, 0.310 30, 1.500 30 C 2.690 30, 3 28.556, 3 23 C 3 17.444, 2.690 16, 1.500 16 C 0.310 16, 0 17.444, 0 23 M 19 18.500 C 19 19.325, 18.561 20, 18.025 20 C 16.962 20, 17.713 25.388, 19.176 28.250 C 19.668 29.212, 21.180 30, 22.535 30 C 25.244 30, 25.876 28.273, 23.500 27.362 C 22.675 27.045, 22 25.709, 22 24.393 C 22 22.933, 22.585 22, 23.500 22 C 24.325 22,25 21.550, 25 21 C 25 20.450, 24.373 20, 23.607 20 C 22.841 20, 21.955 19.325, 21.638 18.500 C 21.322 17.675, 20.599 17, 20.031 17C 19.464 17, 19 17.675, 19 18.500 M 6 25 C 6 28.685, 6.379 30, 7.440 30 C 8.381 30, 8.987 28.703, 9.190 26.250 C 9.566 21.703, 11.309 21.672, 12 26.199 C 12.697 30.768, 16 31.435, 16 27.008 C 16 21.800, 14.450 20, 9.965 20 L 6 20 6 25 M 26 25.100 C 26 28.789, 26.371 30, 27.500 30 C 28.496 30, 29 29.004, 29 27.035 C 29 25.094, 29.690 23.701, 31 23 C 34.032 21.377, 33.402 20.200, 29.500 20.200 L 26 20.200 26 25.100 M 34.655 21.829 C 33.745 22.835, 33 24.340, 33 25.174 C 33 27.285, 36.239 30, 38.757 30 C 43.022 30, 45.722 24.122, 42.800 21.200 C 41.007 19.407, 36.534 19.752, 34.655 21.829 M 37.169 23.231 C 35.670 24.730, 37.585 28.097, 39.515 27.356C 41.384 26.638, 41.459 23.390, 39.622 22.685 C 38.864 22.394, 37.760 22.640, 37.169 23.231 "
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
)
export const Quote = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      fillRule="nonzero"
      d="M24 13v11.25h6.05L26.2 31h4.95L35 24.25V13H24zM9 24.25h6.05L11.2 31h4.95L20 24.25V13H9v11.25z"
    />
  </svg>
)
export const Bold = props => (
  <svg viewBox="0 0 44 44" {...props}>
    <path
      fillRule="nonzero"
      d="M29.473 22.065c2.01-1.402 3.316-3.48 3.316-5.607 0-4.664-3.8-8.458-8.473-8.458H11v29h15.132C30.465 37 34 33.472 34 29.146c0-3.142-1.864-5.824-4.527-7.081zm-12.42-9.232h6.658c2.009 0 3.631 1.62 3.631 3.625a3.623 3.623 0 0 1-3.631 3.625h-6.658v-7.25zm7.868 19.334h-7.868v-7.25h7.868c2.01 0 3.632 1.619 3.632 3.625a3.623 3.623 0 0 1-3.632 3.625z"
    />
  </svg>
)
export const Italic = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      fillRule="nonzero"
      d="M17.333 8v4.667h6.02l-8.54 18.666H8V36h18.667v-4.667h-6.02l8.54-18.666H36V8z"
    />
  </svg>
)

export const Columns = props => (
  <svg viewBox="0 0 44 44" {...props}>
    <g fillRule="evenodd">
      <path d="M4 10h17v4H4zM4 17h15v4H4zM4 31h15v4H4zM4 24h17v4H4z" />
      <g>
        <path d="M24 10h17v4H24zM24 17h15v4H24zM24 31h15v4H24zM24 24h17v4H24z" />
      </g>
    </g>
  </svg>
)

export const UnorderedList = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      d="M 5.389 9.430 C 4.393 12.025, 5.788 14.169, 8.246 13.820 C 11.450 13.365, 11.450 8.635, 8.246 8.180 C 6.893 7.988, 5.750 8.488, 5.389 9.430 M 14 11 C 14 12.901, 14.667 13, 27.500 13 C 40.333 13, 41 12.901, 41 11 C 41 9.099, 40.333 9, 27.500 9 C14.667 9, 14 9.099, 14 11 M 5.389 21.430 C 4.393 24.025, 5.788 26.169, 8.246 25.820 C 11.450 25.365, 11.450 20.635, 8.246 20.180 C6.893 19.988, 5.750 20.488, 5.389 21.430 M 14 23 C 14 24.901, 14.667 25, 27.500 25 C 40.333 25, 41 24.901, 41 23 C 41 21.099, 40.333 21, 27.500 21 C 14.667 21, 14 21.099, 14 23 M 5.389 33.430 C 4.393 36.025, 5.788 38.169, 8.246 37.820 C 11.450 37.365, 11.450 32.635, 8.246 32.180 C 6.893 31.988, 5.750 32.488, 5.389 33.430 M 14 35 C 14 36.901, 14.667 37, 27.500 37 C 40.333 37, 41 36.901, 41 35 C 41 33.099, 40.333 33, 27.500 33 C 14.667 33, 14 33.099, 14 35 "
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
)
export const OrderedList = props => (
  <svg {...props} viewBox="0 0 44 44">
    <path
      fillRule="nonzero"
      d="M5 31.563h3.684v.906H6.842v1.812h1.842v.907H5V37h5.526v-7.25H5v1.813zM6.842 15.25h1.842V8H5v1.813h1.842v5.437zM5 20.688h3.316L5 24.494v1.631h5.526v-1.813H7.211l3.315-3.806v-1.631H5v1.813zm9.21-10.875v3.624H40V9.813H14.21zm0 25.374H40v-3.624H14.21v3.625zm0-10.874H40v-3.625H14.21v3.625z"
    />
  </svg>
)

export const SizeLarge = props => (
  <svg {...props} viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <rect opacity="0.4" width="26" height="3.067" rx="1.533" />
      <rect opacity="0.4" y="19.933" width="26" height="3.067" rx="1.533" />
      <rect opacity="0.7" y="6.133" width="26" height="10.733" rx="1.533" />
    </g>
  </svg>
)
export const SizeMedium = props => (
  <svg {...props} viewBox="0 0 32 23" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <rect opacity="0.4" x="3.048" width="25.905" height="3.067" rx="1.533" />
      <rect opacity="0.4" x="3.048" y="19.933" width="25.905" height="3.067" rx="1.533" />
      <rect y="6.133" width="32" height="10.733" rx="1.533" />
    </g>
  </svg>
)
export const SizeSmall = props => (
  <svg {...props} viewBox="0 0 44 23" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <rect opacity="0.4" x="9" width="26" height="3.067" rx="1.533" />
      <rect opacity="0.4" x="9" y="19.933" width="26" height="3.067" rx="1.533" />
      <rect opacity="0.7" x="0.5" y="6.133" width="43" height="10.733" rx="1.533" />
    </g>
  </svg>
)

export const SizeTwoFifth = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
    <g fillRule="evenodd" transform="translate(1 17)">
      <rect width="17" height="2" x="25" opacity=".4" rx="1" />
      <rect width="15" height="2" x="25" y="5" opacity=".4" rx="1" />
      <rect width="14" height="2" x="25" y="9" opacity=".4" rx="1" />
      <rect width="22" height="11" rx="1.533" />
    </g>
  </svg>
)

export const SizeOneThird = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
    <path
      d="M 1 23 L 1 30.084 8.750 29.792 L 16.500 29.500 16.500 23 L 16.500 16.500 8.750 16.208 L 1 15.916 1 23 M 22 17 C 22 17.604, 26.186 18, 32.559 18 C 39.344 18, 42.897 17.643, 42.500 17 C 42.155 16.442, 37.490 16, 31.941 16 C 25.980 16, 22 16.400, 22 17 M 22 23 C 22 23.593, 25.667 24, 31 24 C 36.333 24, 40 23.593, 40 23 C 40 22.407, 36.333 22, 31 22 C 25.667 22, 22 22.407, 22 23 M 22 29 C 22 29.583, 25.314 30, 29.941 30 C 34.309 30, 38.160 29.550, 38.500 29 C 38.894 28.363, 36.010 28, 30.559 28 C 25.520 28, 22 28.411, 22 29 "
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
)

export const OneColumn = props => (
  <svg {...props} viewBox="0 0 73 43" xmlns="http://www.w3.org/2000/svg">
    <g fill="#43C979" fillRule="evenodd">
      <path d="M0 0h73v7H0zM0 12h61v7H0zM0 24h67v7H0zM0 36h40v7H0z" />
    </g>
  </svg>
)
export const TwoColumns = props => (
  <svg {...props} viewBox="0 0 94 43" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0h43v7H0zM51 0h43v7H51zM0 12h36v7H0zM51 12h36v7H51zM0 24h40v7H0zM51 24h40v7H51zM0 36h24v7H0zM51 36h24v7H51z"
      fill="#43C979"
      fillRule="evenodd"
    />
  </svg>
)
export const ImageColumn = props => (
  <svg {...props} viewBox="0 0 98 43" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M-3-5h54v54H-3z" />
        <path
          d="M45 1H3a3 3 0 0 0-3 3v36a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3zM3 40V4h42v36H3z"
          fill="#43C979"
        />
        <path
          d="M10.38 16a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm0-6.9a2.4 2.4 0 1 1-2.385 2.4 2.4 2.4 0 0 1 2.385-2.385V9.1zM31.17 18.055l-8.1 8.1-6-6a1.5 1.5 0 0 0-2.115 0L5.88 29.35v4.245L16.065 23.41 21 28.27l-5.625 5.625H19.5L32.175 21.22 42 31v-4.23l-8.715-8.715a1.5 1.5 0 0 0-2.115 0z"
          fill="#43C979"
        />
      </g>
      <path d="M55 0h43v7H55zM55 12h36v7H55zM55 24h40v7H55zM55 36h24v7H55z" fill="#43C979" />
    </g>
  </svg>
)
export const Video = props => (
  <svg {...props} viewBox="0 0 52 34" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(2 2)"
      stroke="#43C979"
      strokeWidth="4"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M47.143 4.286L32.143 15l15 10.714z" />
      <rect width="32.143" height="30" rx="4" />
    </g>
  </svg>
)
export const Discussion = props => (
  <svg {...props} viewBox="0 0 52 45" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.5 0H2.25A2.257 2.257 0 0 0 0 2.25v28.744c0 1.237 1.012 2.194 2.25 2.194h26.438v9.562c0 .956.73 1.8 1.575 2.138.28.112.562.112.843.112.675 0 1.294-.281 1.744-.788l9.337-11.025H49.5c1.237 0 2.25-.956 2.25-2.193V2.25A2.257 2.257 0 0 0 49.5 0zm-2.25 28.688h-6.075c-.675 0-1.406.337-1.856.787l-6.132 7.144v-5.625c0-1.238-.787-2.306-2.024-2.306H4.5V4.5h42.75v24.188zM10.462 12.375a2.257 2.257 0 0 1 2.25-2.25h16.313a2.257 2.257 0 0 1 2.25 2.25 2.257 2.257 0 0 1-2.25 2.25H12.712a2.257 2.257 0 0 1-2.25-2.25zm24.694 1.744c-.393-.394-.675-1.013-.675-1.575 0-.619.225-1.181.675-1.575a2.221 2.221 0 0 1 1.575-.675c.619 0 1.181.225 1.575.675.394.394.675 1.012.675 1.575 0 .618-.225 1.181-.675 1.575-.45.45-1.012.675-1.575.675a2.827 2.827 0 0 1-1.575-.675z"
      fill="#43C979"
    />
  </svg>
)

export const Triangle = props => (
  <svg viewBox="0 0 19 37" xmlns="http://www.w3.org/2000/svg">
    <path d="M.213 0l18.213 18.213L0 36.64V0h.213z" fillRule="evenodd" />
  </svg>
)
