import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import {
  Discussion,
  DiscussionVariables
} from '@components/Detail/Discussion/__generated__/Discussion'

export const DISCUSSION = gql`
  query Discussion($id: ID!) {
    discussion(id: $id) {
      id
      title
      commentCount
    }
  }
`

export class DiscussionQuery extends Query<Discussion, DiscussionVariables> {}
