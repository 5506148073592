import { Div, Flex } from '@components/Layout'
import { Text } from '@components/Typography'
import styled from '@styled'
import React from 'react'
import { Label } from './elements'

export const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  padding: 0 0.75rem;
  line-height: 3;
  border: 1px solid ${p => (p.error ? p.theme.colors.red : p.theme.colors.border)};
  border-radius: 0.25rem;
  font-size: 1rem;
  outline: 0;
  &:focus {
    border-color: ${p => (p.error ? p.theme.colors.red : p.theme.colors.primaryDark)};
  }
  &:disabled {
    background: ${p => p.theme.colors.border};
  }
`

type Props = {
  label?: string
  error?: boolean
}

const Input = ({ label, error, ...rest }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Div mb={3}>
      {label && <Label>{label}</Label>}
      <StyledInput error={error} {...rest} />
    </Div>
  )
}

export default Input
