import styled, { createGlobalStyle, theme } from '@styled'
import { darken, size } from 'polished'
import { MenuButton as BaseMenuButton } from '@reach/menu-button'
export { Menu, MenuItem, MenuList } from '@reach/menu-button'

export const MenuButton = styled(BaseMenuButton)`
  transition: color 0.2s ease-in-out;

  color: ${p => p.theme.colors.heading};
  display: flex;
  align-items: center;
  outline: 0;
  cursor: pointer;
  border: none;
  background: none;
  b {
    margin-right: 0.25rem;
  }
  svg {
    margin-left: 0.5rem;
    ${size('.5rem')};
    stroke: currentColor;
  }
  &:hover {
    color: ${p => darken(0.1, p.theme.colors.primaryDark)};
  }
`

export const MenuButtonGlobal = createGlobalStyle`
:root {
  --reach-menu-button: 1;
}
  [data-reach-menu] {
    font-family: ${theme.colors.body};
    display: block;
    position: absolute;
    z-index: 100;
  }

  [data-reach-menu-list] {
    white-space: nowrap;
    border: ${theme.colors.border};
    background: #fff;
    outline: none;
    list-style-type: none;
    padding: 10px 0;
    margin: 0;
    box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.15);
  }

  [data-reach-menu-item] {
    /* li */
    cursor: pointer;

    /* a */
    display: block;
    color: inherit;
    font: inherit;
    text-decoration: initial;

    /* both */
    padding: 5px 20px;
    &:hover {
      color: ${theme.colors.primaryDark};
    }
  }

  [data-reach-menu-item][data-selected] {
    color: ${theme.colors.primaryDark};
    outline: none;
  }`
