import React, { Component } from 'react'
import { ContentContainer } from '@components/Detail/Containers'
import { Container, TitleContainer, Carrot, Title, Content, IconContainer } from './elements'
import { Information } from '@components/Icons'
import { convert } from '../Text/utils'
import { convertFromRaw } from 'draft-js'
import ContentStyles from '../Text/ContentStyles'
export interface Props {
  content: any
}

export interface State {
  open: boolean
}

class CollapsableText extends React.Component<Props, State> {
  state = { open: false }
  render() {
    const { title, text } = this.props.content
    const { html } = convert(convertFromRaw(text))
    return (
      <ContentContainer>
        <Container open={this.state.open}>
          <TitleContainer
            role="button"
            onClick={() => this.setState(({ open }) => ({ open: !open }))}
          >
            <IconContainer>
              <Information />
            </IconContainer>
            <Title>{title}</Title>
            <Carrot />
          </TitleContainer>
          <Content>
            <ContentStyles dangerouslySetInnerHTML={{ __html: html }} />
          </Content>
        </Container>
      </ContentContainer>
    )
  }
}

export default CollapsableText
