import { ContentContainer } from '@components/Detail/Containers'
import { Triangle } from '@components/Icons'
import { Div } from '@components/Layout'
import React from 'react'
import { HeadingOne, Wrapper } from './elements'
import { theme } from '@styled'

export const Title = ({ children }) => (
  <Wrapper mt={5}>
    <Div
      position="absolute"
      left="0"
      height="2rem"
      width="1rem"
      top=".6rem"
      style={{ fill: theme.colors.primary }}
    >
      <Triangle />
    </Div>
    <ContentContainer mb={'2rem'}>
      <HeadingOne>
        <span>{children}</span>
      </HeadingOne>
    </ContentContainer>
  </Wrapper>
)
