import React, { Fragment } from 'react'
import Recommendations from './Recommendations'
import Remaining from './Remaining'
import Hero from './Hero'
import Cta from './Cta'
import LastComments from './LastComments'
import LoggedIn from '@components/LoggedIn'
import Logos from './Logos'
const Home = () => {
  return (
    <Fragment>
      <LoggedIn>{({ isLoggedIn }) => <Hero isLoggedIn={isLoggedIn} />}</LoggedIn>
      <Recommendations />
      <Remaining />
      <LoggedIn>{({ isLoggedIn }) => (!isLoggedIn ? <Cta /> : <LastComments />)}</LoggedIn>
      <Logos />
    </Fragment>
  )
}

export default Home
