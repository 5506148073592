import { LoadingImage, LoadingText } from '@components/ContentLoaders'
import { Div } from '@components/Layout'
import { Feed_feed } from '@scenes/Home/__generated__/Feed'
import image from '@utils/image'
import React, { Fragment } from 'react'
import {
  CommentCount,
  ContainerHorizontal,
  ContentContainerHorizontal,
  Image,
  ImageContainerHorizontal,
  Text,
  TitleHorizontal
} from './elements'
import { getAltText } from '@utils/alt-text'

type Props = {
  loading?: boolean
  href: string
  article?: Feed_feed
}

const Horizontal = ({ loading, href, article }: Props) => {
  return (
    <ContainerHorizontal to={href}>
      <ImageContainerHorizontal>
        {loading ? (
          <LoadingImage />
        ) : (
          <Image
            small={
              article && article.image ? image(article.image.path, { w: 750, fit: 'crop' }) : null
            }
            medium={
              article && article.image ? image(article.image.path, { w: 600, fit: 'crop' }) : null
            }
            alt={
              article &&
              article.image &&
              article.image.unsplashAuthor &&
              article.image.unsplashAuthorUsername
                ? getAltText(article.image.unsplashAuthor, article.image.unsplashAuthorUsername)
                : ``
            }
          />
        )}
        {/* {!loading && article ? <CommentCount count={article.commentCount} /> : null} */}
      </ImageContainerHorizontal>
      <ContentContainerHorizontal>
        {loading ? (
          <Div mt={3}>
            <LoadingText height={'1.5rem'} mb={2} />
            <LoadingText height={'1.5rem'} mb={2} width="90%" />
            <LoadingText height={'1.5rem'} mb={2} width="80%" />
          </Div>
        ) : (
          <TitleHorizontal>
            <span>{article ? article.title : null}</span>
          </TitleHorizontal>
        )}

        <Text>
          {loading ? (
            <Div mt={4}>
              <LoadingText mb={2} />
              <LoadingText mb={2} width="90%" />
              <Fragment>
                <LoadingText mb={2} width="80%" />
                <LoadingText mb={2} width="90%" />
                <LoadingText mb={2} width="80%" />
              </Fragment>
            </Div>
          ) : article ? (
            article.description
          ) : null}
        </Text>
      </ContentContainerHorizontal>
    </ContainerHorizontal>
  )
}

export default Horizontal
