import { ImageVariables, Image } from './__generated__/Image'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { MenuItems } from './__generated__/MenuItems'
import { File, FileVariables } from './__generated__/File'

export const IMAGE = gql`
  query Image($id: ID!) {
    image(id: $id) {
      id
      path
      createdAt
      unsplashAuthor
      unsplashAuthorUsername
    }
  }
`

export class ImageQuery extends Query<Image, ImageVariables> {}

export const MENU_ITEMS = gql`
  query MenuItems {
    menuItems {
      id
      title
      menuTitle
      slug
    }
  }
`
export class MenuItemsQuery extends Query<MenuItems, {}> {}

export const FILE = gql`
  query File($id: ID!) {
    file(id: $id) {
      id
      filename
      mimetype
      createdAt
    }
  }
`

export class FileQuery extends Query<File, FileVariables> {}
