import React, { Component } from 'react'
import Information from './Information'
import Link from './Link'

type Props = {
  parentOffset: number
  elements: Array<HTMLElement>
}

type Item = { offset: number; element: HTMLElement | HTMLAnchorElement }

type State = {
  active: string | null
  informationActive: string | null
  items: Array<Item>
}

export class AsideElements extends Component<Props, State> {
  state = {
    informationActive: null,
    active: null,
    items: []
  }
  componentDidMount() {
    this.setState({ items: this.getItems() })
    // this.getItems()
    this.props.elements.forEach(el => {
      el.addEventListener('mouseenter', () => {
        this.setActive(el.id)
      })
      el.addEventListener('mouseleave', () => {
        this.setActive(null)
      })
      if (el.dataset.type === 'INFORMATION') {
        el.addEventListener('click', () => {
          this.setInformationActive(el.id)
          el.classList.toggle('information--open')
        })
      }
    })
  }

  setActive = active => this.setState({ active })
  setInformationActive = id => {
    if (this.state.informationActive === id) {
      this.setState({ informationActive: null })
    } else {
      this.setState({ informationActive: id })
    }
  }

  getItems = () => {
    let items: Array<{ offset: number; element: HTMLElement | HTMLAnchorElement }> = []
    let total = 0
    this.props.elements.forEach((element, index) => {
      let offset = 0
      const offsetRelativeToContainer = element.offsetTop - this.props.parentOffset
      if (index === 0) {
        offset = offsetRelativeToContainer - 10
      } else {
        const prev = items[index - 1]
        const diff = offsetRelativeToContainer - total
        if (diff <= 60) {
          // Make sure there is enough space beween this and the previous element
          offset = 0
        } else {
          offset = offsetRelativeToContainer - total - 10
        }
      }
      total += offset + 65
      items.push({ offset, element })
    })
    return items
  }

  render() {
    const { items } = this.state
    return !items.length
      ? null
      : items.map((item: Item, index) => {
          const { offset, element } = item
          // @ts-ignore
          const { href, dataset, innerText } = element
          const { type, description } = dataset
          return type === 'LINK' ? (
            <Link
              key={`aside-${index}`}
              offset={offset}
              title={innerText}
              href={href}
              active={this.state.active}
              connection={element.id}
            />
          ) : null
          // <Information
          //   key={`aside-${index}`}
          //   offset={offset}
          //   title={innerText}
          //   description={description ? description : ''}
          //   active={this.state.active}
          //   connection={element.id}
          //   onClick={() => this.setInformationActive(element.id)}
          //   open={this.state.informationActive === element.id}
          // />
        })
  }
}

export default AsideElements
