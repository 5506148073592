import styled, { media } from '@styled'
import { Div } from '@components/Layout'
import { NavLink } from 'react-router-dom'
import right from './footer-right.png'

export const StyledFooter = styled(Div)`
  background: ${p => p.theme.colors.secondary};
  color: #fff;
  position: relative;
`

export const RightImage = styled.div`
  width: 155px;
  height: 155px;
  top: -50px;
  right: 0;
  position: absolute;
  background: url(${right});
  background-size: 100% 100%;
`

export const Logo = styled.img``

export const Link = styled(NavLink)`
  display: block;
  transition: color 0.2s ease-in-out;
  font-size: 1rem;
  margin-right: 1rem;
  color: #fff;
  letter-spacing: -0.2px;
  text-decoration: none;
  &.active,
  &:hover,
  &:focus {
    color: ${p => p.theme.colors.primaryDark};
  }

  ${media.lessThan('small')`
    margin: .5rem 0;
  `};
`
