import React from 'react'
import { Container as OverlayContainer, Inner } from './elements'
import { Flex, Div } from '@components/Layout'
import { CloseButton } from './elements'
import Navigation from '../Navigation'
import LoggedIn from '@components/LoggedIn'
import { Modals } from '@config/appContext'
import { Primary, PrimaryInverse } from '@components/Buttons'
import { Text } from '@components/Typography'
type Props = {
  isOpen: boolean
  children: React.ReactChild
  close: () => void
  setActiveModal: (modal: Modals) => void
}
const MobileMenu = ({ isOpen = false, close, children, setActiveModal }: Props) => {
  return (
    // @ts-ignore
    <OverlayContainer
      // pose={isOpen ? 'open' : 'closed'}
      style={{
        transform: isOpen ? `translateY(0)` : `translateY(-2000px)`
      }}
      position="fixed"
      left="0"
      top="0"
      width="100%"
      height="100%"
    >
      <Inner>
        <Flex justifyContent="flex-end">
          <CloseButton onClick={close} />
        </Flex>
        <Div p={3}>
          <Div>
            <Text fontWeight={500} fontSize={30}>
              Menu
            </Text>
          </Div>

          <Navigation close={close} />

          {/* <LoggedIn>
            {({ isLoggedIn }) =>
              !isLoggedIn ? (
                <Div mt={4}>
                  <Div mb={3} maxWidth={['100%', 300]}>
                    <Primary
                      style={{ width: '100%' }}
                      onClick={() => {
                        close()
                        setActiveModal('login')
                      }}
                    >
                      Inloggen
                    </Primary>
                  </Div>
                  <Div mb={3} maxWidth={['100%', 300]}>
                    <PrimaryInverse
                      style={{ width: '100%' }}
                      onClick={() => {
                        close()
                        setActiveModal('register')
                      }}
                    >
                      Registeren
                    </PrimaryInverse>
                  </Div>
                </Div>
              ) : null
            }
          </LoggedIn> */}
        </Div>
      </Inner>
    </OverlayContainer>
  )
}

export default MobileMenu
