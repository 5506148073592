import styled from '@styled'
import { Flex, Div } from '@components/Layout'
import { rem } from 'polished'

export const StyledHeader = styled(Div)`
  width: 100%;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

export const HeaderContainer = styled(Flex)`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem(1440)};
  height: ${rem(60)};
`
