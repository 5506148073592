export const HOME = '/'
export const STYLEGUIDE = '/styleguide'
export const ARTICLE_DETIAL = '/a/:id'
export const PAGE_DETAIL = '/p/:id'

// Auth routes
export const PROFILE = '/profiel'

// Admin routes
export const ADMIN = '/admin'
export const ADMIN_ARTICLES_OVERVIEW = '/admin/articles'
export const ADMIN_ARTICLES_DETAIL = '/admin/articles/:id'
export const ADMIN_PAGES_OVERVIEW = '/admin/pages'
export const ADMIN_PAGES_DETAIL = '/admin/pages/:id'
export const ADMIN_USERS_OVERVIEW = '/admin/users'
export const ADMIN_USERS_DETAIL = '/admin/users/:id'
export const ADMIN_LOGIN = '/admin/login'
