import React, { Fragment } from 'react'
import { Div, Grid, Container, Flex } from '@components/Layout'
import { HeadingOne, Text } from '@components/Typography'
import { LastCommentsQuery, LAST_COMMENTS } from '../queries'
import { Title, Comment, Avatar, Author, AuthorDescription, AvatarImage } from './elements'
import image from '@utils/image'
import { UnderlinedLinkButton } from '@components/Buttons'
import { withRouter, RouteComponentProps } from 'react-router'
import * as routes from '@config/routes'

function stripWords(str: string): string {
  const words = str.split(' ')
  return `${words.slice(0, 35).join(' ')}${words.length > 55 ? '...' : ''}`
}

function LastComments({ history }: RouteComponentProps) {
  return (
    <Fragment>
      <HeadingOne>Laatste reacties</HeadingOne>
      <Container>
        <Div mt={4} mb={5}>
          <LastCommentsQuery query={LAST_COMMENTS}>
            {({ loading, error, data }) => {
              if (loading || error || !data) return null
              return (
                <Grid gridTemplateColumns={['100%', 'repeat(2, 1fr)']} gridColumnGap={[0, '4rem']}>
                  {data &&
                    data.lastComments &&
                    data.lastComments.map(comment => {
                      return (
                        <Div key={comment.id} mb={[4, 0]}>
                          <Text fontSize={23} fontWeight="bold">
                            Discussie in
                          </Text>
                          <Title>{comment.discussion.article.title}</Title>

                          <Comment>{stripWords(comment.text)}</Comment>
                          <Flex alignItems="center">
                            <Avatar>
                              {comment.user.avatar && (
                                <AvatarImage
                                  src={image(comment.user.avatar.filename, {
                                    w: 150,
                                    h: 150,
                                    fit: 'crop'
                                  })}
                                />
                              )}
                            </Avatar>
                            <Div>
                              <Author>{comment.user.display_name}</Author>
                              {comment.user.description && (
                                <AuthorDescription>{comment.user.description}</AuthorDescription>
                              )}
                            </Div>
                          </Flex>
                          <Div mt={2}>
                            <UnderlinedLinkButton
                              onClick={() => {
                                const { id, slug } = comment.discussion.article
                                const discussionId = comment.discussion.id
                                history.push(
                                  routes.ARTICLE_DETIAL.replace(
                                    ':id',
                                    `${slug}-${id}#discussion-${discussionId}`
                                  )
                                )
                              }}
                            >
                              Discussieer mee
                            </UnderlinedLinkButton>
                          </Div>
                        </Div>
                      )
                    })}
                </Grid>
              )
            }}
          </LastCommentsQuery>
        </Div>
      </Container>
    </Fragment>
  )
}

export default withRouter(LastComments)
