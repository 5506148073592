import styled from '@styled'

export const ImgInside = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  background-color: ${p => p.theme.colors.border};
  object-position: center center;
  top: 0;
  /* &::before {
    padding-top: 56.25%;
    content: '';
    display: block;
  } */
`

export const ImgOutside = styled.img`
  background-color: ${p => p.theme.colors.border};
  margin: 0 auto;
  display: block;
  width: 100%;
`
