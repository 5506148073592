import React from 'react'
import { Aside, Container, Content } from './elements'

type ContentContainerProps = {
  children: JSX.Element[] | JSX.Element
  side?: React.ReactChild | null
  padding?: boolean
  hasSide?: boolean
  mt?: number | Array<number> | Array<string> | string
  mb?: number | Array<number> | Array<string> | string
  innerRef?: (any) => void
}

export const ContentContainer = ({
  children,
  side,
  padding = true,
  hasSide = true,
  innerRef,
  mb = ['2rem', '3rem'],
  mt
}: ContentContainerProps) => (
  <Container mt={mt} mb={mb} ref={innerRef}>
    <Content padding={padding}>{children}</Content>
    {hasSide && <Aside>{side && side}</Aside>}
  </Container>
)

export const ImageContainer = ({ children }) => <div>{children}</div>
