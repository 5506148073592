import { Div, Flex } from '@components/Layout'
import React from 'react'
import { ContentContainer } from '../Containers'
import { HeadingTwo, Text } from '@components/Typography'
import { FileQuery, FILE } from '@graphql/queries'
import { Icon, Button } from './elements'
import { File } from '@components/Icons'
import { Primary } from '@components/Buttons'
import { format } from 'date-fns'
import image from '@utils/image'
import { s3 } from '@utils/s3'

const Files = ({ content }) => {
  if (!content) {
    return null
  }
  return (
    <ContentContainer>
      <HeadingTwo>{content.title}</HeadingTwo>
      {content.files &&
        content.files.map(id => (
          <FileQuery key={id} query={FILE} variables={{ id }}>
            {({ loading, data, error }) => {
              if (loading || error || !data || !data.file) {
                return null
              }
              return (
                <Flex mb={3} alignItems="center" flexWrap="wrap" justifyContent="space-between">
                  <Div width="4rem">
                    <Icon>
                      <File />
                    </Icon>
                  </Div>
                  <Div width={['calc(100% - 4rem)', '60%']} px={3}>
                    <Text fontSize={20} mb={2}>
                      {data.file.filename.split('-').pop()}
                    </Text>
                    <Text fontSize={17} opacity={0.5}>
                      {format(data.file.createdAt, 'D MMMM')}
                    </Text>
                  </Div>
                  <Div width={['100%', 'auto']} mt={['1.5rem', 0]} mb={[2, 0]}>
                    <Button href={s3(data.file.filename)} target="_blank">
                      Downloaden
                    </Button>
                  </Div>
                </Flex>
              )
            }}
          </FileQuery>
        ))}
    </ContentContainer>
  )
}

export default Files
