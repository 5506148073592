import React, { Fragment } from 'react'
import { FeedQuery, FEED, HomepageArticleType } from '@scenes/Home/queries'
import ArticlePreview from '@components/ArticlePreview'
import { Container, Grid, Div } from '@components/Layout'
import { HeadingOne } from '@components/Typography'

const Remaining = () => {
  return (
    <Div mb={5}>
      <HeadingOne>Alle artikelen</HeadingOne>
      <Container>
        <Grid
          gridTemplateColumns={['1fr', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
          gridColumnGap={[0, '1.5rem']}
          gridRowGap={['2rem', '1.5rem']}
        >
          <FeedQuery
            query={FEED}
            ssr={false}
            variables={{
              type: HomepageArticleType.REMAINING
            }}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return [...Array.from(new Array(4))].map((u, i) => {
                  return (
                    <ArticlePreview
                      variant="vertical"
                      key={`remaining-loading-${i}`}
                      loading={true}
                    />
                  )
                })
              }
              if (error || !data) {
                // TODO: proper error state
                return <div>Error</div>
              }
              return data.feed.map(article => {
                return <ArticlePreview key={article.id} variant="vertical" article={article} />
              })
            }}
          </FeedQuery>
        </Grid>
      </Container>
    </Div>
  )
}

export default Remaining
