import React, { Component, Fragment } from 'react'
import { Wrapper, Placeholder, Image } from './elements'
import imageHelper from '@utils/image'
import { getAltText } from '@utils/alt-text'

const Hero = ({ image, loading }) => {
  return (
    <Wrapper>
      {image && (
        <Fragment>
          <Placeholder
            style={{
              backgroundImage: `url(${imageHelper(image.path, { w: 10, h: 10, crop: 'fit' })})`
            }}
          />
          <Image
            small={imageHelper(image.path, { w: 750, crop: 'fit' })}
            medium={imageHelper(image.path, { w: 1200, crop: 'fit' })}
            large={imageHelper(image.path, { w: 1440, crop: 'fit' })}
            huge={imageHelper(image.path, { w: 1920, crop: 'fit' })}
            alt={
              image && image.unsplashAuthor && image.unsplashAuthorUsername
                ? getAltText(image.unsplashAuthor, image.unsplashAuthorUsername)
                : ``
            }
          />
        </Fragment>
      )}
    </Wrapper>
  )
}

export default Hero
