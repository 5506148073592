import { Div } from '@components/Layout'
import styled, { media } from '@styled'
import { rem, rgba } from 'polished'
import { space, SpaceProps } from 'styled-system'

export const Wrapper = styled(Div)`
  width: 100%;
  position: relative;
`

export const HeadingOne = styled.h1<SpaceProps>`
  ${space};
  font-size: ${rem(40)};
  line-height: 1.2;
  overflow-wrap: break-word;
  hyphens: auto;
  ${media.greaterThan('medium')`
    font-size: ${rem(40)};
  `};
  ${media.greaterThan('large')`
    font-size: ${rem(40)};
  `};
  ${media.greaterThan('huge')`
    font-size: ${rem(50)};
  `};
  span {
    display: inline;
    background-size: 1px 1em;
    box-shadow: inset 0 -0.1em white, inset 0 -0.3em ${p => rgba(p.theme.colors.primary, 0.6)};
  }
`

HeadingOne.defaultProps = {
  mb: 4
}
