import React, { Consumer, Provider } from 'react'

export type Modals = 'login' | 'register' | 'reset-password' | 'validate-reset-password' | null

export type State = {
  mobileNavActive: boolean
  modalActive: Modals
}

export type AppMethods = {
  toggleMobileNavActive: () => void
  setActiveModal: (modalActive: Modals) => void
}

export type AppState = State & AppMethods

const Context = React.createContext<AppState | null>(null)

export const AppProvider = Context.Provider
export const AppConsumer = Context.Consumer
