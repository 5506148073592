import React, { Fragment } from 'react'
import { CenteredContent, Div } from '@components/Layout'
import { theme } from '@styled'
import { Text } from '@components/Typography'
import { Input } from '@components/FormFields'
import { Primary } from '@components/Buttons'
import { Formik, FormikProps, Form } from 'formik'
import { LoginMutation, LOGIN } from '@graphql/mutations'
import { UPDATE_LOGGED_IN_STATUS } from '@graphql/linkState'
import { Mutation } from 'react-apollo'
import * as Yup from 'yup'
import { ErrorNotice } from '@components/Notices'

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required()
})

interface FormValues {
  email: string
  password: string
}

const Login = () => {
  return (
    <CenteredContent>
      <Div border={`1px solid ${theme.colors.border}`} minWidth={400} p={4} borderRadius={5}>
        <Mutation mutation={UPDATE_LOGGED_IN_STATUS}>
          {updateLoggedInStatus => (
            <LoginMutation mutation={LOGIN}>
              {(login, { loading, error }) => (
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={schema}
                  onSubmit={async (variables: FormValues) => {
                    const result = await login({ variables })
                    if (result && result.data) {
                      window.localStorage.setItem('token', result.data.login.token)
                      updateLoggedInStatus({ variables: { isLoggedIn: true } })
                    }
                  }}
                  render={({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    values,
                    errors,
                    touched
                  }: FormikProps<FormValues>) => (
                    <form onSubmit={handleSubmit}>
                      <Fragment>
                        <Text fontSize={25} fontWeight={500} mb={4}>
                          Inloggen
                        </Text>
                        <Input
                          type="text"
                          name="email"
                          placeholder="E-mail adres"
                          label="E-mail adres"
                          value={values.email}
                          error={touched.email && typeof errors.email !== 'undefined'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Input
                          type="password"
                          name="password"
                          label="Wachtwoord"
                          placeholder="Wachtwoord"
                          value={values.password}
                          error={touched.password && typeof errors.password !== 'undefined'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Div width="100%" pt={2}>
                          <Primary disabled={loading} style={{ width: '100%' }} type="submit">
                            {loading ? `Inloggen...` : `Inloggen`}
                          </Primary>
                        </Div>
                        <ErrorNotice
                          message={error ? 'Dit e-mail of wachtwoord is niet bekend.' : null}
                        />
                      </Fragment>
                    </form>
                  )}
                />
              )}
            </LoginMutation>
          )}
        </Mutation>
      </Div>
    </CenteredContent>
  )
}

export default Login
