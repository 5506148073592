import React, { Component } from 'react'
import qs from 'query-string'
import { ContentContainer } from '../Containers'
import { Container, Button } from './elements'
import { Flex } from '@components/Layout'
import { Facebook, Twitter, LinkedIn } from '@components/Icons'

export class Share extends Component<{ title: string; description: string }> {
  shareOnFacebook = () => {
    const { origin, pathname } = window.location
    const url = `https://www.facebook.com/sharer.php?u=${origin}${pathname}`
    this.open(url)
  }
  shareOnTwitter = () => {
    const shareURL = 'http://twitter.com/share?'
    const { origin, pathname } = window.location

    const params = {
      url: `${origin}${pathname}`,
      text: this.props.description
    }
    this.open(`${shareURL}?${qs.stringify(params)}`)
  }
  shareOnLinkedIn = ()  => {
    const shareURL = 'https://www.linkedin.com/shareArticle'
    const { origin, pathname } = window.location
    const params = {
      url: `${origin}${pathname}`,
      mini: 'true'
    }
    this.open(`${shareURL}?${qs.stringify(params)}`)
  }
  open(url: string) {
    window.open(
      url,
      '',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
    )
  }
  render() {
    return (
      <ContentContainer>
        <Container alignItems="center" justifyContent="space-between">
          <h3>Artikel delen</h3>
          <Flex>
            <Button onClick={this.shareOnFacebook}>
              <Facebook />
              <span>Facebook</span>
            </Button>
            <Button onClick={this.shareOnTwitter}>
              <Twitter />
              <span>Twitter</span>
            </Button>
            <Button onClick={this.shareOnLinkedIn}>
              <LinkedIn></LinkedIn>
              <span>LinkedIn</span>
            </Button>
          </Flex>
        </Container>
      </ContentContainer>
    )
  }
}

export default Share
