import { Flex } from '@components/Layout'
import styled from '@styled'
import React from 'react'
import { Check } from '@components/Icons'

const Item = styled(Flex)`
  position: relative;
  cursor: pointer;
`
const CustomCheckbox = styled.div`
  transition: all 0.2s ease-in-out;
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
const Label = styled.label`
  padding-left: 1.75rem;
  display: inline-block;
  a {
    color: ${props => props.theme.colors.primary};
  }
`
const CheckboxInput = styled.input`
  display: none;
  + ${CustomCheckbox} svg {
    display: none;
    fill: #fff;
    width: 90%;
    height: 90%;
  }
  &:checked + ${CustomCheckbox} {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    svg {
      display: block;
    }
  }
`

type Props = {
  checked?: boolean
  label: React.ReactChild
  name: string
  id: any
}

type CheckboxProps = Props & React.InputHTMLAttributes<HTMLInputElement>

const Checkbox = ({ label, checked, name, id, ...rest }: CheckboxProps) => (
  <Item alignItems="center" mb={2}>
    <CheckboxInput name={name} type="checkbox" id={id} checked={checked} {...rest} />
    <CustomCheckbox>
      <Check />
    </CustomCheckbox>
    <Label htmlFor={id}>{label}</Label>
  </Item>
)

export default Checkbox
