import qs from 'query-string'
type ImageArgs = {
  w?: number
  h?: number
  fit?: string
  crop?: string
}

const image = (path: string, args?: ImageArgs): string | undefined => {
  if (!path) {
    return ''
  }
  if (!args) {
    args = { w: 100, h: 100 }
  }
  // TODO: improve this
  if (path.includes('unsplash')) {
    return `${path}&${qs.stringify(args)}&utm_source=Identiteitsplein&utm_medium=referral`
  } else {
    return `https://identiteitsplein.imgix.net/${path}?${qs.stringify(args)}`
  }
}

export default image
