import React from 'react'
import { Container } from './elements'

type Props = {
  ratio?: string
  children: React.ReactChild | null
}

const Ratio = ({ ratio = '56.25%', children }: Props) => (
  <Container ratio={ratio}>{children}</Container>
)

export default Ratio
