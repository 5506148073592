import React from 'react'
import styled from '@styled'
import { Text } from '@components/Typography'
import { rem, rgba } from 'polished'
import { Div } from '@components/Layout'

export const Avatar = styled.div`
  margin-left: 1rem;
  width: ${rem(50)};
  position: relative;
  height: ${rem(50)};
  border-radius: ${rem(25)};
  overflow: hidden;
  background: ${p => p.theme.colors.border};
`

export const AvatarImage = styled.img`
  width: ${rem(50)};
  height: ${rem(50)};
  position: absolute;
  top: 0;
  left: 0;
`

export const Name = styled(Text)`
  font-weight: semibold;
  color: ${p => p.theme.colors.primaryDark};
`

export const Date = styled(Text)`
  opacity: 0.5;
  margin-left: 0.5rem;
`

export const Container = styled(Div)`
  background: #fff;
  padding: 1rem;
  position: relative;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 15px;
    left: 0;
    box-sizing: border-box;

    border: 0.5rem solid black;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(-315deg);

    box-shadow: -1px 2px 2px 0 rgba(0, 0, 0, 0.15);
  }
`

export const Textarea = styled.textarea`
  transition: background 0.2s ease-in-out;
  border: 0;
  width: 100%;
  min-height: 100px;
  font-size: ${rem(16)};
  line-height: 1.4;
  padding: 0.5rem;
  border-radius: 0.5rem;
  &:focus {
    outline: 0;
    background: ${p => rgba(p.theme.colors.primaryDark, 0.1)};
  }
`
