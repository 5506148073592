import styled, { media } from '@styled'
import { Flex } from '@components/Layout'
import { rem } from 'polished'

export const Container = styled(Flex)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media.greaterThan('large')`
    max-width: ${rem(1000)};
  `} ${media.greaterThan('huge')`
    max-width: ${rem(1150)};
  `};
`
export const Content = styled.section<{ padding?: boolean }>`
  ${p => p.padding && `padding: 0 1.5rem`};
  width: 100%;
  ${media.greaterThan('medium')`
    ${p => p.padding && `padding: 0 1.5rem`};
  `};
  ${media.greaterThan('large')`
    ${p => p.padding && `padding: 0 2rem`};
  `};
  ${media.greaterThan('huge')`
    ${p => p.padding && `padding: 0 3rem`};
  `};
`
export const Aside = styled.aside`
  display: none;
  ${media.greaterThan('large')`
    display:block;
    width: ${rem(400)};
  `};
`
