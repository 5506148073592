import styled from '@styled'

export const CleanButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  cursor: pointer;
`
