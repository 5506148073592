import { createGlobalStyle, theme } from '@styled'
import { normalize, fontFace } from 'polished'

// @ts-ignore
const publicPath = '/'

export const GlobalStyle = createGlobalStyle`
  ${normalize()};
  ${fontFace({
    fontFamily: 'Leitura',
    fontWeight: 'normal',
    fileFormats: ['woff2', 'woff'],
    fontFilePath: `${publicPath}fonts/leitura-roman`
  })};

  ${fontFace({
    fontFamily: 'Lota',
    fontWeight: 'normal',
    fileFormats: ['woff2', 'woff'],
    fontFilePath: `${publicPath}fonts/lota-regular`
  })};
  ${fontFace({
    fontFamily: 'Lota',
    fontWeight: '500',
    fileFormats: ['woff2', 'woff'],
    fontFilePath: `${publicPath}fonts/lota-semibold`
  })};
  ${fontFace({
    fontFamily: 'Lota',
    fontWeight: 'bold',
    fileFormats: ['woff2', 'woff'],
    fontFilePath: `${publicPath}fonts/lota-bold`
  })};
  ${fontFace({
    fontFamily: 'Lota',
    fontWeight: 'regular',
    fontStyle: 'italic',
    fileFormats: ['woff2', 'woff'],
    fontFilePath: `${publicPath}fonts/lota-regular-italic`
  })};

  * {
    box-sizing: border-box;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: antialiased;
    vertical-align: baseline;
  }
  html.modal-active {
    overflow:hidden;
  }
  html,body {
    font-family: 'Lota', sans-serif;
  }
  h1, h2, h3, h4 ,h5, h6 {
    font-family: 'Leitura', sans-serif !important;
    margin: 0;
    letter-spacing: .2px;
    color: ${theme.colors.heading};
  }


`
