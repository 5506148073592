import React, { Component } from 'react'
import { Div, Flex } from '@components/Layout'
import { IconContainer, ContentContainer, Title, Text, LinkContainer } from './elements'
import { External } from '@components/Icons'

type Props = {
  offset: number
  title: string
  href: string
  active: string | null
  connection: string | null
}

class Link extends Component<Props, {}> {
  render() {
    const { offset, title, href, connection, active } = this.props
    const cleanedHref = href.replace('mailto:', '')
    return (
      <Div position="relative" mt={offset} width="100%">
        <LinkContainer href={href} target="blank" active={connection === active}>
          <IconContainer>
            <External />
          </IconContainer>
          <ContentContainer>
            <Title>{title}</Title>
            <Text>{`${
              cleanedHref.length > 26 ? `${cleanedHref.slice(0, 26)}...` : cleanedHref
            }`}</Text>
          </ContentContainer>
        </LinkContainer>
      </Div>
    )
  }
}

export default Link
